import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import styled  from 'styled-components';

import { useAppContext } from '../context/AppContext';
import hardwareData from '../../data/hardwareData';
import hardwareTypeData from '../../data/hardwareTypeData';
import Nav from '../atom/Nav';
import HardwareTypeContainer from '../molecule/HardwareTypeContainer';
import NextBar from '../atom/NextBar';

const HardwareWrapper = styled.div`
  .container {
   position: relative;
  }
  h2 {
    font-family: "Clear Sans Pro", Clear Sans Pro;
    font-size: 50px;
    color: #252525;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    max-width: 650px;
    @media (max-height: 710px) {
      max-width: 100%;
    }
  }
  h4 {
    font-size: 18px;
    color: #252525;
    font-weight: 400;
    font-weight: 600;
    position: relative;
    top: -10px;
  }
  .description {
    font-size: 15px;
    color: #555555;
    line-height: 20px;
    max-width: 600px;
    font-weight: 100;
  }
  .top-margin {
    margin-top: 30px;
  }
  .scenario {
    border: 1px solid black;
    display: inline-block;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 10px;
    @media (max-width: 768px) {
      position: relative;
    }
    span {
      color: #000000;
      font-weight: 400;
      display: inline-block;
      padding-right: 30px;
      @media (max-width: 768px) {
        padding-right: 5px;
      }
    }
  }
  .select-row {
    display: flex;
    .colA {
      h4 {
        margin: 0;
        font-size: 85px;
        width: 50px;
      }
    }
    .colB {
      flex-grow: 1;
    }
  }
  .configRow {
    position: relative;
    margin-bottom: 25px;
    margin-top: 20px;
    & > div {
      display: inline-block;
    }
  }
  .addconfig {
    background: white;
    font-size: 13px;
    color: #252525;
    text-align: center;
    width: 181.5px;
    border: 1px solid black;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
`;

function Scenario() {
  let history = useHistory();

  const initialLocalHardwareState = {'config1':[], 'config2':[]};

  // global state to store users final hardware selection
  const { state, dispatch } = useAppContext();

  // local state to store users running hardware selections
  const [selectedHardware, setSelectedHardware] = useState((state.hardware.config1.length > 0) ? state.hardware : initialLocalHardwareState);

  // local state for determing if we display the config2 options or not
  const [isConfig2Enabled, setIsConfig2Enabled] = useState((selectedHardware.config2.length > 0) ? true : false);

  // use must have scenario selected before they can be here
  if (!state.scenario || !state.scenario.id) {
    history.push('/scenario');
  }

  // handle user adding hardware to selections in local state
  function addHardware(hardwareId, configId, e) {
    // validate call
    if (!hardwareId || ! configId) {
      console.log('Missing params');
      return;
    }

    // make a copy of what's in local state to avoid mutation
    let updatedSelectedHardware = {...selectedHardware};

    // get full data for selected hardware from our data store
    const hardwareSelection = hardwareData.find(x => x.id === hardwareId);

    if(!hardwareSelection) {
      console.log('Invalid selection');
      return;
    }

    // see the hardware user just clicked is already in their selections (local state)
    const selectionIndex = updatedSelectedHardware[configId].findIndex(x => x.id === hardwareId);

    // add the new selection to previous selections only IF it's not already in local state
    if (selectionIndex === -1) {
      // TODO: make sure user only has 1 CPU selected
      // add hardware to config
      updatedSelectedHardware[configId].push(hardwareSelection);
    }
    // remove hardware if it's already in local state for this config
    else {
      updatedSelectedHardware[configId].splice(selectionIndex, 1);
    }

    // set updated selections back to local state
    setSelectedHardware(updatedSelectedHardware);
  };

  // saves hardware to global state //passes into NextBar
  function selectHardware(e) {
    // dispatch action so reducer can update state with selected hardware,
    dispatch({type: 'SET_HARDWARE', payload: selectedHardware});

    // clear local state (this is prob not needed)
    setSelectedHardware(initialLocalHardwareState);

    // use router send user to next page
    history.push('/run');
  };

  // determine if user selections are valid such to show Run button/link
  function isNextValid() {

    // must have config 1 hardware
    if (!selectedHardware.config1.length > 0){
      return false;
    }
    // must have 1 CPU selected in config1
    else if (!selectedHardware.config1.find(x => x.type === 'CPU')) {
      return false;
    }
    // cannot have more than one non-CPU selected
    else if (!selectedHardware.config1.length > 2) {
      return false;
    }

    // if config 2 exists
    if (isConfig2Enabled){
      // must have CPU selected in config 2
      if (!selectedHardware.config2.find(x => x.type === 'CPU')) {
        return false;
      }
      // cannot have more than one non-CPU selected
      else if (!selectedHardware.config2.length > 2) {
        return false;
      }
    }

    return true;
  }

  // handle showing second hardware config selector
  function addConfig () {
    setIsConfig2Enabled(true);
  }

  // build the data for our groups (by type) of hardware for each config (1 & 2)
  let config1HardwareGroups = [],
    config2HardwareGroups = [];

  for (var i = 0; i < hardwareTypeData.length; i++) {
    const thisType = hardwareTypeData[i].id;
    config1HardwareGroups[thisType] = hardwareData.filter(x => x.type === thisType);
    config2HardwareGroups[thisType] = hardwareData.filter(x => x.type === thisType);
  }

  // build our hardware type containers for display for each config (1 & 2)
  let config1HardwareDisplayGroups = [],
    config2HardwareDisplayGroups = [];

  for (let [hardwareTypeId, hardwareValues] of Object.entries(config1HardwareGroups)) {
    const thisHardwareTypeData = hardwareTypeData.find(x => x.id === hardwareTypeId);
    config1HardwareDisplayGroups.push(<HardwareTypeContainer key={hardwareTypeId} configId="config1" hardwareType={thisHardwareTypeData} selectedHardware={selectedHardware} hardware={hardwareValues} addHardware={addHardware} displayInfo={"show"} />)
  }

  for (let [hardwareTypeId, hardwareValues] of Object.entries(config1HardwareGroups)) {
    const thisHardwareTypeData = hardwareTypeData.find(x => x.id === hardwareTypeId);
    config2HardwareDisplayGroups.push(<HardwareTypeContainer key={hardwareTypeId} configId="config2" hardwareType={thisHardwareTypeData} selectedHardware={selectedHardware} hardware={hardwareValues} addHardware={addHardware} displayInfo={"show"} />)
  }

  return (
    <Fragment>
      <Nav />
      <HardwareWrapper className="bootstrap-wrapper isNavBar">
        <div className={"container"}>

        <div className={"scenario"}>
          <span>Scenario:</span> { state.scenario && state.scenario.title }
        </div>
          <h2>Select hardware</h2>
          <h5>Choose a single CPU option or select a CPU and an accelerator to build a hardware configuration to run your AI scenario on.</h5>
          <p className={"description"}>{ /* state.scenario && state.scenario.selectHardware */}</p> <br />
        <div className={"top-margin"}>

          <div className={"select-row"}>
            <div className={"colA"}>
              <h4>1</h4>
            </div>
            <div className={"colB"}>
              <div className="configRow">
                {config1HardwareDisplayGroups}
              </div>
            </div>
          </div>

          {!isConfig2Enabled &&
          <div>
            <p>Want to compare two configurations?</p>
            <div className="addconfig" onClick={addConfig}><img src={"/images/icons/plus.svg"} className={"plus"} alt={"plus"}/>Add Configuration</div>
          </div>
          }
          {isConfig2Enabled &&
          <div>
            <div className={"select-row"}>
              <div className={"colA"}>
                <h4>2</h4>
              </div>
              <div className={"colB"}>
                <div className="configRow">
                  {config2HardwareDisplayGroups}
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        {/* TODO: figure out if we should call another component here */}
        {isNextValid() &&
          <NextBar myFunction={selectHardware} title="Run Test" />
        }
        </div>
      </HardwareWrapper>
    </Fragment>
  );
}

export default Scenario;
