import React, {Fragment, useState} from "react";
import styled  from 'styled-components';
import CodeExample from "../atom/CodeExample";

const CodeExampleStyle = styled.div`
  .viewCode {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    background: #0071C5;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  .bib {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 10;
    background: rgba( 0, 0, 0, .26);
    display: flex;
    justify-content: center;
    align-items: center;
    &.hide {
      display: none;
    }
    .popup {
      max-width: 820px;
      width: 100%;
      background: white;

    }
    .overflow {
      padding: 20px;
      padding-top: 0;
      overflow: auto;
      max-height: 440px;
      height: 100%;
      text-align: left;
    }
    .closebox {
      position: absolute;
      right: 25px;
      cursor: pointer;
    }
    .title {
       background: #00AEEF;
       color: white;
       height: 75px;
       text-align: left;
       padding-right: 25px;
       padding-left: 25px;
       display: flex;
       align-items: center;
       position: relative;
       a {
        font-weight: bold;
        color: white;
       }
    }
    span {
      display: inline-block;
    }
  }
 }`;

function CodeExampleContainer(props) {
  const [showInfo, setShowInfo] = useState(false);
  const clickHandler = () => {
    setShowInfo(showInfo ? false : true);
  }
  // add show/hide css class based on showInfo state var
  const infoDisplayClass = (showInfo) ? 'show' : 'hide';

  return(
    <Fragment>
      <CodeExampleStyle>
        <div className={"" + props.displayInfo}>

          <div className={"viewCode"}  onClick={clickHandler}><span>&#60; / &#62;	 View Code Sample</span></div>
          <div className={`${infoDisplayClass} bib`}>
            <div className={"popup"}>
                <div>
                  <div className={"title"}>
                    Full code samples available by registering for Intel® Devcloud for the Edge. &nbsp; <a href={"https://devcloud.intel.com/edge/"} target={"_blank"} rel={"noopener noreferrer"}>Sign Up</a>.
                    <img src={"/images/icons/close.svg"} alt={"close"} onClick={clickHandler} className={"closebox"}/>
                  </div>
                  <div className={"overflow"}>
                    <CodeExample />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </CodeExampleStyle>
    </Fragment>
  );
};
export default CodeExampleContainer;
