import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// pages
import Splash from './pages/Splash';
import Scenario from './pages/Scenario';
import Hardware from './pages/Hardware';
import Run from './pages/Run';
import Results from './pages/Results';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Splash />
          </Route>
          <Route path="/scenario">
            <Scenario />
          </Route>
          <Route path="/hardware">
            <Hardware />
          </Route>
          <Route path="/run">
            <Run />
          </Route>
          <Route path="/results">
            <Results />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
