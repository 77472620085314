import {createGlobalStyle} from 'styled-components';
import './normalize.css';
import './bootstrap-grid.css';
import './font.css';
import './prism.css';
export const GlobalCSS = createGlobalStyle`
  body {
    font-family: Clear Sans, "Clear Sans";
    font-size: 18px;
    background: url(/images/background/grid-sm-c.png);
    background-repeat: repeat;
    background-size: 100px 100px;
  }
  a {
    text-decoration: none;
  }
  .isNavBar {
    padding-left: 200px;
  }
  .white {
    color: white;
  }
  .text-center {
    text-align: center;
  }
`;
