import React, {Fragment} from "react";
import styled  from 'styled-components';
import InfoCard from "./InfoCard";
import { useAppContext } from '../context/AppContext';

const IntelCard = styled.div`
  max-width: 133px;
  text-align: center;
  &.padding {
    margin-right: 20px;
  }
  .card {
    background: #FFFFFF;
    border: 1px solid #252525;
    border-radius: 6px;
    width: 135px;
    height: 167px;
    font-size: 13px;
    text-align: center;
    padding: 7px;
    position: relative;
    cursor: pointer;
    color: black;
    &.selected {
      border: 1px solid #fbce38;
      background: #fbce38;
      opacity: 0.98;
      box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    }
    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
    img {
      width: 100%;
      max-width: 75px;
      position: absolute;
      bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }
  .title {
    margin-top: 7px;
    margin-bottom: 10px;

  }
`;

function HardwareCard(props) {
  const { state } = useAppContext();
  // figure state of this card (nada, selected, disabled)
  let cardClass = '';  // init to nada
  
 // if this hardware is found in selections
  if (props.selectedHardware && props.selectedHardware[props.configId].find(x => x.id === props.hardware.id)) {
    cardClass = 'selected';
       
  }
  // if this hardware card is for a CPU and a CPU is already selected
  else if (props.selectedHardware && props.hardware.type ==='CPU' && props.selectedHardware[props.configId].find(x => x.type === props.hardware.type)) {
    cardClass = 'disabled';
  }
  // if this hardware card is for a non-CPU and the user has not yet selected a CPU
  else if (props.selectedHardware && props.hardware.type !=='CPU' && !props.selectedHardware[props.configId].find(x => x.type === 'CPU')) {
    cardClass = 'disabled';
  }
  // if the user has already made two hardware selections for this config (max is 2)
  else if (props.selectedHardware && props.selectedHardware[props.configId].length > 1) {
    cardClass = 'disabled';
  }
  // The Atom CPUs do not get additional acceleration units
  else  if (props.selectedHardware && props.hardware.type ==='FPGA' && props.selectedHardware[props.configId].find(x => x.id === "atom") ) {
    cardClass = 'disabled';
  }
  //The Atom CPUs do not get VPU
  else  if (props.selectedHardware && props.hardware.type ==='VPU' && props.selectedHardware[props.configId].find(x => x.id === "atom") ) {
    cardClass = 'disabled';
  }
  //The Xeon CPUs do not get GPU
  else  if (props.selectedHardware && props.hardware.type ==='GPU' && props.selectedHardware[props.configId].find(x => x.id === "xeon") ) {
    cardClass = 'disabled';
  }
  //The Xeon doesn't work with FGPA on the intrusion test
  else  if (props.selectedHardware && state.scenario.id === "intrusion" && props.hardware.type ==='FPGA' && props.selectedHardware[props.configId].find(x => x.id === "xeon") ) {
    cardClass = 'disabled';
  }
  
  // if scenario is intrusion FPGA is disabled with CPU
  else  if (props.selectedHardware && state.scenario.id === "intrusion" && props.hardware.type ==='FPGA' && props.selectedHardware[props.configId].find(x => x.id === "i7") ) {
    console.log(state.scenario.id === "intrusion");
    console.log(props.hardware.type ==='FPGA');
    cardClass = 'disabled';
  
  }


  return(
    <Fragment>
      <IntelCard className={props.cssClass}>
        {/* if card is disabled, no onclick */}
        <div onClick={cardClass === 'disabled' ? () => false : (e) => props.addHardware(props.hardware.id, props.configId, e)}  className={cardClass + " card"}>
          <div className={"title"}>{props.hardware.name}</div>
          <img src={`/images/hardware/${props.hardware.id.toLowerCase()}.png`} alt={props.hardware.name} className={".chip"}/>
        </div>
        <InfoCard displayInfo={props.displayInfo} description={props.hardware.description} learnmore={props.hardware.learnmore} />
      </IntelCard>
    </Fragment>
  );
};

export default HardwareCard;
