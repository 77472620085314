import React, { Fragment } from "react";
import styled from 'styled-components';

const ProcessorType = styled.span`
  font-family: "Clear Sans Pro",Clear Sans Pro;
  font-size: 32px;
  margin-right: 10px;
  @media (min-width: 768px) and (max-width: 1100px) {
    font-size: 22px;
    margin-right: 7px;
  }

`;
const H4 = styled.h4`
  font-size: 72px;
  margin: 0;
  line-height: 12px;
  display: inline-block;
  //margin-right: 20px;
`;


const ProcessorName = styled.span`
  font-family: "Clear Sans", "Intel Clear";
  font-size: 17px;
  color: #555555;
  line-height: 12px;
  font-weight: normal;
  @media (min-width: 768px) and (max-width: 1100px) {
    font-size: 13px;
  }
`;

function ResultsTitle(props) {
  return (
    <Fragment>
        <H4>
          <ProcessorType>{props.type}</ProcessorType>
          <ProcessorName>{props.name}</ProcessorName>
        </H4>
    </Fragment>
  );
}

export default ResultsTitle;
