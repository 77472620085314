import React, { Fragment } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Share from '../atom/Share';


import { useAppContext } from '../context/AppContext';

const NavContainer = styled.div`
  background-color: rgba(0, 84, 198, 1.000);
  background-image: url(/images/background/nav.jpg);
  background-size: 100% 100%;
  max-width: 177px;
  width: 100%;
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 10;
  top:0;
  box-shadow: 0 2px 70px 0 rgba(0,0,0,0.20);
  @media (max-width: 600px) {
    max-width: 120px;
  }
  h3 {
    font-family: "Clear Sans Pro", Clear Sans Pro;
    font-size: 34px;
    color: #FFFFFF;
    line-height: 28px;
    padding-left: 20px;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .nav li {
    max-width: 100%;
    text-align: right;
    padding-bottom: 20px;
    position: relative;
    a:hover {
       opacity: 0.8;
      }
    span {
      color: white;
      display: inline-block;
      padding-right: 15px;
      font-size: 16px;

    }
  }
  .secondary {
    position: absolute;
    bottom: 80px
  }
  .secondary li {
    padding-left: 20px;
    padding-bottom: 20px;
    color: white;
    font-size: 13px;
    cursor: pointer;
    a:hover {
       opacity: 0.8;
    }
    a {
      color: white;
    }
  }
  .sliderContainer {
    position: relative;
    height: 125px;
  }
  .intel {
     max-width: 60px;
     margin-left:20px;
     position: absolute;
     bottom: 30px;
  }

`;

const Dot = styled.img`
  max-width: 24px;
  position: absolute;
  right: -13px;
`;
const Slider = styled.img`
 position: absolute;
  right: -15px;
`;


function Nav() {
  const { dispatch } = useAppContext();
  const location = useLocation();
  let history = useHistory();

  function startOver(e) {
    dispatch({type: 'RESET', payload: null});
    history.push('/');
  }

  return (
    <Fragment>
      <NavContainer>
        <h3>Intel® <br />Devcloud  <br/>for the <br/> Edge</h3>
        <ul className={"nav"}>
          {(()=> {
            if (location.pathname === "/scenario") {
              return (<div className={"sliderContainer"}><Slider src={"/images/navigation/slider.svg"} /></div>)
            }
          })()}
          {(()=> {
            if (location.pathname === "/hardware") {
              return (
                <Fragment>
                  <li><Link to="/scenario" ><span>Select a Computer Vision Scenario</span></Link><Dot src={"/images/navigation/dot.png"} alt="dot"/></li>
                  <div className={"sliderContainer"}><Slider src={"/images/navigation/slider.svg"} /></div>
                </Fragment>
                )
            } else {
              return (
                <li><span>Select Hardware</span><Dot src={"/images/navigation/dot.png"} alt="dot"/></li>
              )
            }
          })()}

          {(()=> {
            if (location.pathname === "/results") {
              return (<div className={"sliderContainer"}><Slider src={"/images/navigation/slider.svg"} /></div>)
            }
          })()}
          <li>
            {/* note: this is never a Link because the nav is never visible at a time when the user could navigate back here */}
            <span>View Performance Results</span>
            <Dot src={"/images/navigation/dot.png"} alt="dot"/>
          </li>
        </ul>

        <ul className={"secondary"}>
          {/* TODO: start over needs to look like a link */}
          <li onClick={startOver}>Start Over</li>
          <li><a href="https://devcloud.intel.com/edge/" target="_blank" rel={"noopener noreferrer"}>Sign Up</a></li>
          <Share cssClass={"nav"}/>
        </ul>
        <img className="intel" src={"/images/logo/intel-logo.svg"} alt={"Intel"}/>
      </NavContainer>
    </Fragment>
  );
}

export default Nav;
