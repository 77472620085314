import React, {Fragment, useState} from "react";
import styled  from 'styled-components';

const InfoStyles = styled.div`
  .info {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .arrow_box_container {
    position:relative;
    display: none;
    &.show {
      display: block;
    }
  }
  .arrow_box {
    position: absolute;
    background: #FFF;
    border: 1px solid #D2D2D2;
    font-size: 12px;
    padding: 3px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 190px;
    z-index: 14;
    border-radius: 5px;
    box-shadow: 0 2px 34px 0 rgba(37,37,37,0.16);
    p {
      padding-left: 12px;
      padding-right: 12px;
    }
    &.hide{
      display: none;
    }
    &.show {
      display: block;
    }
    .close {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    &:after, &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: white;
      border-width: 8px;
      margin-left: -8px;
    }
    &:before {
      border-color: rgba(194, 210, 210, 0);
      border-bottom-color: #D2D2D2;
      border-width: 9px;
      margin-left: -9px;
    }
  }`;

function InfoCard(props) {
  const [showInfo, setShowInfo] = useState(false);
  const clickHandler = () => {
    setShowInfo(showInfo ? false : true);
  }
  // add show/hide css class based on showInfo state var
  const infoDisplayClass = (showInfo) ? 'show' : 'hide';

 return(
    <Fragment>
      <InfoStyles>
        <div className={"arrow_box_container " + props.displayInfo}>
          <img src={"/images/icons/info.svg"} className={"info"} onClick={clickHandler} alt="more info icon" />
          <div className={`${infoDisplayClass} arrow_box`}>
            <img className={"close"} src={"/images/icons/chevron-small-down.svg"} onClick={clickHandler} alt="close icon" />
            <p>{props.description}
            {props.learnmore
               ? <span><br /><a href={props.learnmore} target={"_blank"} rel={"noopener noreferrer"}>Learn More</a></span>
              : ""
            }</p>

          </div>
        </div>
      </InfoStyles>
    </Fragment>
  );
};
export default InfoCard;
