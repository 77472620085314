import React, {Fragment} from "react";
import styled from 'styled-components';
import ResultsTitle from "../atom/ResultTitle";
import ResultsGraph from "../atom/ResultsGraph";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .marginBottom {
    margin-bottom: 16px;
  }
  h4 {
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;
const H5 = styled.h6`
  font-size: 72px;
  margin: 0;
  line-height: 12px;
  display: inline-block;
  @media (min-width: 768px) and (max-width: 1100px) {
    font-size: 50px;
  }
`



function ResultsCardContainer(props) {



  const ConfigNames = props.config.map((item) =>
    <ResultsTitle key={item.id} type={item.type} name={item.name}  />
  );

  return (
    <Fragment>
      <Container className={"col-sm newCol"}>
        <div className={"marginBottom"}>
          <H5>{props.bigNumber}</H5>
          {ConfigNames}

        </div>
        <ResultsGraph config={props.config} />

      </Container>
    </Fragment>
  );
}

export default ResultsCardContainer;
