import React, { Fragment, useState } from "react";
import styled  from 'styled-components';

const ShareContainer = styled.li`
  position: relative;
  .hide {
    display: none;
  }
  .show {

  }
  #shareit {
    cursor: pointer;
  }
  .shareMenu {
      position: absolute;
      background: #FFF;
      border: 1px solid #D2D2D2;
      font-size: 12px;
      padding: 3px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 312px;
      z-index: 14;
      border-radius: 5px;
      box-shadow: 0 2px 34px 0 rgba(37,37,37,0.16);
      opacity: 1 !important;
      top: 26px;
      .close {
        position: absolute;
        top: 15px;
        right: 7px;
        cursor:pointer;
        display: block;
        z-index: 10;
      }
      &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: white;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(194, 210, 210, 0);
        border-bottom-color: #D2D2D2;
        border-width: 9px;
        margin-left: -9px;
      }

    ul {
      padding-left: 0 !important;
      margin-left: 0;
      list-style: none;
      li {
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 0;
        text-align: left;
        display: inline-block;
        min-width: 92px;
        span {
          display: inline-block;
        }
        img {
          max-width: 16px;
          display: inline-block;
          margin-top: -4px;
            margin-right: 9px;
            max-height: 18px;
        }
          a {
           color: white;
           font-weight: normal;
           background: #0071c5;
           border-radius: 5px;
           padding: 8px 13px;
           display: flex;

        }
      }
    }
  }
  &.splash {
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    padding-left: 35px;
    .shareMenu {
      left: auto;
      right: -80px;
      top: -11px;

      &:after, &:before {
        bottom: 36%;
        left: auto;
        right: -16px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: white;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(194, 210, 210, 0);
        border-left--color: #D2D2D2;
        border-width: 9px;
        margin-left: -9px;
      }
    }

    #shareit {
      &:hover {
        opacity: 0.8;
      }
    }
    a {
      color: white;
      font-size: 12px;
    }
    img {
      position: relative;
      top: 2px;
    }
    .signup {
      top: 5px
    }
  }
  &.results {
    background-color: #0071C5;
    color: white;
    border-right: 1px white solid;
    height: 38px;
    width: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    .shareMenu {
      left: auto;
      right: 27px;
      top: 0px;
      &:after, &:before {
        bottom: 36%;
        left: auto;
        right: -16px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: white;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(194, 210, 210, 0);
        border-left--color: #D2D2D2;
        border-width: 9px;
        margin-left: -9px;
      }
      .close {
        margin-right: 0;
      }
    }
    img {
      margin-right: 10px;
    }
    a {
    color: white;
    }
  }
  &.nav {
    img {
      display: none;
    }
    .shareMenu {
      left: 247px;
      right: auto;
      top: -13px;
      min-width: 337px;
      .close {
        right: 8px;
        top: 16px;
      }
      a {
        span {
        font-size: 12px !important;
        }

      }
      &:after, &:before {
        bottom: 36%;
        left: -10px;
        right: auto;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: white;
        border-width: 9px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(194, 210, 210, 0);
        border-right-color: #D2D2D2;
        border-width: 9px;
        margin-left: -9px;
      }
    }
  }
`;


function Share (props) {
  const [showShare, setShowShare] = useState(false);
  const clickHandler = () => {
    setShowShare(showShare ? false : true);
  }
  const shareDisplayClass = (showShare) ? 'show' : 'hide';

  const autoFill = `I+discovered+a+free+way+to+experiment+with+AI+and+develop+computer+vision+prototypes+using+the+Intel(r)+DevCloud+for+the+Edge.++To+learn+more,+check+out+https://devcloud.intel.com/edge`;
  const autoFillEmail = `I%20discovered%20a%20free%20way%20to%20experiment%20with%20AI%20and%20develop%20computer%20vision%20prototypes%20using%20the%20Intel(r)%20DevCloud%20for%20the%20Edge.%20%20To%20learn%20more,%20check%20out%20https://devcloud.intel.com/edge`;
  return (
    <Fragment>
      <ShareContainer className={props.cssClass} onClick={clickHandler} >
        <div id={"shareit"}><img src={"/images/icons/rss.svg"} alt={"share"}/> Share</div>
        <div className={`shareMenu  ${shareDisplayClass}`}>
          <img className={"close"} src={"/images/icons/chevron-small-down.svg"} onClick={clickHandler} alt="close icon" />
            <ul>
              <li><a href={"https://twitter.com/intent/tweet?text=" + autoFill} target={"_blank"}  rel="noopener noreferrer" ><img src={"/images/icons/twitter.svg"} alt={"twitter"} /> <span>Tweet</span></a></li>
              <li><a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://devcloud.intel.com/edge&title=Intel(r)+DevCloud+for+the+Edge&source=LinkedIn&summary=" + autoFill} target={"_blank"}  rel="noopener noreferrer" ><img src={"/images/icons/linkedin-logo.svg"} alt={"linkedin"}/> <span>LinkedIn</span></a></li>
              <li><a href={"mailto:?&subject=Intel®%20Devcloud&body=" + autoFillEmail}><img src={"/images/icons/mail-logo.svg"} alt={"mail"} /> <span>Send</span></a></li>
            </ul>
        </div>
      </ShareContainer>
    </Fragment>
  )
}

export default Share;
