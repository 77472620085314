import React, {useContext, useReducer, createContext} from "react";
import appInitialState from './appInitialState';
import appReducer from './appReducer';


export const AppContext = createContext(appInitialState);

// instead of calling useContext directly in our components,
// we make our own hook that throws an error if we try to
// access context outside of the provider
export function useAppContext() {
  const context = useContext(AppContext)
  if (!context)
    throw new Error('AppContext must be used with AppProvider!')
  return context
}

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, appInitialState);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
}

