import React, { Fragment } from "react";
import styled from 'styled-components'
import { Link } from "react-router-dom";
import Share from '../atom/Share';


const breakPoint = "1024px";

const SplashPage = styled.div `
  background: #3496dd;
  height: 100vh;
  background: radial-gradient(86% 77%, rgba(0, 113, 197, 0.8) 0%, rgba(0, 83, 156, 0.8) 43%,  rgba(0, 60, 113, 1) 100%);
  background-repeat:  no-repeat;
  background-position:  top left;
  background-size: 100%;
  overflow: hidden;
  position: relative;
  @media (max-height: 700px) {
     overflow: auto;
    }
  @keyframes splashAnimation {
    0%   {
      transform: rotate(0deg) scale(1, 1);
    }
    25% {
    transform: rotate(4deg) scale(1.2, 1.2);
    }
    75% {
     transform: rotate(-2.5deg) scale(1.1, 1.1);
    }

    100% {
      transform: rotate(0deg) scale(1, 1);
    }
  }
  &:before{
    position:absolute;
    left:0;
    top:0;
    content: "";
    background-image: url(/images/background/worn-dots-repeat.png);
    background-repeat: repeat;
    background-size: 222px 148px;
    opacity:0.4;
    width: 100%;
    height: 100vh;
    mix-blend-mode: overlay;
  }
  &:after {
    position:absolute;
    left:0;
    top:0;
    content: "";
    background-image: url(/images/background/lightsbmp.jpg);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    opacity:0.4;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vw;
    overflow: hidden;
    perspective: 1200px;
    mix-blend-mode: overlay;
    animation-name: splashAnimation;
    animation-duration: 55s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    @media (max-height: 700px) {
     animation-name: none;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 102px;
    font-family: "Clear Sans Pro",  Clear Sans Pro;
    color: white;
    margin-bottom: 0.17em;
    margin-top: 0;
    @media (max-width: ${breakPoint}) {
      font-size: 80px;
    }
  }
  h3 {
    font-size: 24px;
    color: #FFFFFF;
    position: relative;
    max-width: 222px;
    margin: auto;
    margin-top: 15px;
    text-decoration: none;
  }
  h4 {
    color: #F3D54E;
    font-size: 58px;
    position: absolute;
    top: -0.5em;
    margin: 0;
    padding: 0;
    @media (max-width: ${breakPoint}) {
      font-size: 36px;
    }

  }
  .col-sm {
    position: relative;
  }
  .share {
    color: white;
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 15px;
    font-weight: bold;
    a {
      color: white;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    li {
      display: inline-block;
      padding-left: 35px;
      img {
        position: relative;
        top: 2px;
        margin-right: 4px;
      }
      .signup {
        top: 5px
      }

    }
  }
  .container {
    position: relative;
    z-index: 10;
    overflow: auto;
  }
  .button {
    max-width: 222px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media (max-width: ${breakPoint}) {
      max-width: calc(100% - 50px);
    }
    img {
      max-width: 100%;
    }
  }
  .leadText {
    color: white;
    font-size: 18px;
    max-width: 677px;
    margin-bottom: 79px;
    @media (max-width: ${breakPoint}) {
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
  .intel {
    width: 82px;
    margin-top: 30px;
  }
  .startSimulation {
    background: rgba(251, 206, 56, 1.000);
    box-shadow: 0 2px 64px 0 rgba(255,255,255,0.38);
    color: black;
    border-radius: 4px;
    width: 280px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    //height: 48px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    margin-top: 60px;
    &:hover {
      opacity: 0.8;
    }
  }
  .disclaimer{
    padding-left:20px;
    max-width:800px;
  }
`

function Splash() {

  return (
    <Fragment >
      <SplashPage className={"bootstrap-wrapper"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-sm"}>
              <img src={"/images/logo/intel-logo.svg"} alt={"Intel Logo"} className={"intel"}/>
                <ul className={"share"}>
                  <Share cssClass={"splash"}/>
                  <li><a href="https://devcloud.intel.com/edge/" target="_blank" rel="noopener noreferrer"><img src={"/images/icons/signup.svg"} alt={"sign up"} className={"signup"}/> Sign Up</a></li>
                </ul>
              <h2>Intel® Devcloud for the Edge</h2>
              <p className={"leadText"}>In a few clicks, we’ll show you how the Intel® DevCloud for the Edge can be used to experiment with computer vision applications on a wide range of Intel® hardware.  Once you’ve experienced the examples here, sign up for the Intel® Devcloud for the Edge, download the OpenVino Toolkit™ and learn more about Intel®  hardware to get started building your own applications.</p>
            </div>

          </div>
          <div className={"row"}>
            <div className={"col-sm"}>
              <h4>1</h4>
              <div className={"button"}>
                <img src="/images/splash/step_1.svg" alt="Select Scenario"/>
              </div>
              <h3>Select a Computer <br />Vision Scenario</h3>
            </div>
            <div className={"col-sm"}>
              <h4>2</h4>
              <div className={"button"}>
                <img src="/images/splash/step_2.png" alt={"Select Hardware"}/>
              </div>
              <h3>Select Hardware </h3>
            </div>
            <div className={"col-sm"}>
              <h4>3</h4>
              <div className={"button"}>
                <img src="/images/splash/step_3.svg" alt={"Results"} />
              </div>
              <h3>Results</h3>
            </div>
          </div>
          <div className={"row"}>
              <Link to="/scenario">
                 <div className={"startSimulation"}>Start Demo</div>
              </Link>
              <p className={"leadText disclaimer"}>The Benchmark results seen in this demonstration are estimations based on real test results from the Intel® Devcloud for the Edge. Their purpose is to demonstrate the capabilities of the Intel® Devcloud for the Edge. They are not intended to be used as valid performance benchmarks. Benchmark results published by Intel or appearing on the Intel® DevCloud for the Edge website are measured on specific systems or components using specific hardware and software configurations, and any differences between those configurations (including software) and your configuration may very well make those results inapplicable to your component or system. 
                <a href="https://edc.intel.com/content/www/us/en/products/performance/benchmarks/benchmarks-and-measurements/">
                More...
                </a>
              </p>
          </div>
        </div>
      </SplashPage>
    </Fragment>
  );
}

export default Splash;
