import React, { Fragment } from "react";
import styled from 'styled-components';
import graphData from '../../data/graphData';
import { useAppContext } from '../context/AppContext';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';




const orientationBarWidth = "20px";
const Graph = styled.div`
  .topbar {
    background: #F9F9F9;
    border-bottom: 1px solid #555555;
    .col:first-of-type {
      border-right: 1px solid #555555;
    }
    .title {
      padding: 10px 5px;
      font-weight: bold;
    }
  }
  .background {
      background: white;
      line-height: 0;
      img {
        max-width: 100%;
      }
  }
  .containerGraph {
    border: 1px solid #878787;
    font-size: 14px;
    .belowData {
      background: #F9F9F9;
      border-top: 1px solid #555555;
    }
  }
  .paddingL {
    padding-left: 23px;
  }
  .paddingR {
    //padding-right: 23px;
  }
  .graphs {
    display: flex;
    min-height: 200px;
    & > div {
      display: flex;
      flex: 1;
    }
  }
  canvas {
    width: 100% !important;
  }
  .bordered {
    border-left: 1px solid #555555;
  }
  .orientation {
    min-width: ${orientationBarWidth};
    position:relative;
    border-right: 1px solid #979797;
    span {
      position: absolute;
      bottom: 0;
      display: block;
      transform: rotate(-90deg);
      transform-origin: top left;
      line-height: 1em;
      min-width: 149px;
    }


  }
  .col2 {
    & > .row > .col {
      padding-top: 12px;
      padding-bottom: 12px;
      &:first-of-type {
        border-right: 1px solid #555555;
      }
    }
    .paddingLeft {
      padding-left: 20px;
    }
    .paddingRight {
      padding-right: 20px;
    }
  }
  label {
    padding-left: 7px;
  }
  .marginBottom12 {
   padding-bottom: 12px;
  }
  .flexshrink {
    flex-shrink: 1;
    flex-grow: 0;
  }
  .blue {
    color: #0071c5;
  }
`;

function ResultsGraph (props) {
  const { state } = useAppContext();
  /* enabling max fps and throughput is a multistep process, as it needs to be rendered.
  Uncomment out the lines in the file to display them with avgThroughput maxThroughput*/
 // let maxFPS;
  let avgFPS;
 // let maxThroughput;
  let avgThroughput;
  let maxThroughputGraphScale = parseInt(graphData[state.scenario.id].graphScales.maxThroughput);
  //console.log("maxThroughputGraphScale: " + maxThroughputGraphScale)
  console.log([state.scenario.id]);
  let maxFPSGraphScale = parseInt(graphData[state.scenario.id].graphScales.maxFPS);
  let additionalClass = "paddingR"
  //if hardware config has more than one selected piece of hardware, grab the appropriate image
  if (props.config[1]) {

    //maxFPS = graphData[state.scenario.id][props.config[0].id][props.config[1].id].maxfps;
    avgFPS = graphData[state.scenario.id][props.config[0].id][props.config[1].id].avgfps;
    //maxThroughput = graphData[state.scenario.id][props.config[0].id][props.config[1].id].maxthroughput;
    avgThroughput = graphData[state.scenario.id][props.config[0].id][props.config[1].id].avgthroughput;
  } else {
    // otherwise grab the graph for a singular hardware.
    //maxFPS = graphData[state.scenario.id][props.config[0].id].solo.maxfps;
    avgFPS = graphData[state.scenario.id][props.config[0].id].solo.avgfps;
    //maxThroughput = graphData[state.scenario.id][props.config[0].id].solo.maxthroughput;
    avgThroughput = graphData[state.scenario.id][props.config[0].id].solo.avgthroughput;
  }

  const maxFPSgraph = {
    //labels: ['Max FPS', 'Average FPS', 'Max Throughput', 'Avg. Throughput'],
    //labels: ['Max FPS'],
    datasets: [
      {
        //  data: [`${maxFPS}`, `${avgFPS}`, `${maxThroughput}`, `${avgThroughput}`],
        data: [`${avgFPS}`],
        backgroundColor: '#0071c5',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0,
        maxBarThickness: 110,
        barPercentage: 0.5,

      }
    ]
  };
  const maxThroughputgraph = {
    //labels: ['Max Throughput'],
    datasets: [
      {
        data: [`${avgThroughput}`],
        label: 'dddd',
        backgroundColor: '#0071c5',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0,
        maxBarThickness: 110,
        barPercentage: 0.5,
      }
    ]
  };


  return (
    <Fragment>
      <Graph className={"graphic"}>
        <div className={"containerGraph"}>
          <div className={"topbar"}>
            <div className={"row"}>
              <div className={"col"}>
                <div className={"title"}>FPS</div>
              </div>
              <div className={"col"}>
                <div className={"title"}>Inference Time</div>
              </div>
            </div>
          </div>

          <div className={"background " + additionalClass }>
            <div className={"graphs"}>
              <div className={"graph-single"}>
                <div className={"orientation"}><span>frames per second</span></div>
                <div className={"graph-bar"}><Bar
                  data={maxFPSgraph}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,

                    title:{
                      display:true,
                      fontSize:0
                    },
                    legend:{
                      display: false,
                    },
                    tooltips: {
                      enabled: false
                    },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: '#0071c5',
                        font: {
                          family: 'Clear Sans, Intel Clear, "Clear Sans"',
                          size: "50",
                          weight: "bold"
                        },
                        align: "end",
                        offset: "-16",
                        anchor: "end"
                      }
                    },
                    scales: {
                      yAxes: [{
                        display: true,
                        gridLines: {
                          drawBorder: false,
                        },
                        ticks: {
                          stepValue: maxFPSGraphScale/10,
                          max: maxFPSGraphScale,
                          beginAtZero: true,   // minimum value will be 0.
                          padding: -25,
                          labelOffset: -6,
                        }
                      }]
                    }
                  }}
                /></div>

              </div>
              <div className={"bordered graph-single "}>
                <div className={"orientation"}><span>time, seconds</span></div>
                <div className={"graph-bar"}><Bar
                  data={maxThroughputgraph}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    title:{
                      display:false,
                      fontSize:12
                    },
                    tooltips: {
                      enabled: false
                    },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: '#0071c5',
                        font: {
                          family: 'Clear Sans, Intel Clear, "Clear Sans"',
                          size: "50",
                          weight: "bold"
                        },
                        align: "end",
                        offset: "-16",
                        anchor: "end"
                      }
                    },
                    scaleShowLabels : false,
                    scaleFontSize: 0,
                    legend:{
                      display: false
                    },
                    layout: {
                      padding: {
                        top: 30,
                        bottom:0
                      }
                    },

                    scales: {
                      yAxes: [{
                        display: true,
                        gridLines: {
                          drawBorder: false,
                        },
                        ticks: {
                          stepValue: maxThroughputGraphScale/10,
                          max: maxThroughputGraphScale,
                          beginAtZero: true,   // minimum value will be 0.
                          padding: -25,
                          labelOffset: -6,
                          tickMarkLength: 0,
                        }
                      }]
                    }
                  }}
                /></div>
              </div>
            </div>


          </div>
          <div className={"belowData col2"}>
            <div className={"row"}>
              <div className={"col"}>
                {/* add marginBottom12 class to row if unhiding the max fps
                  Go to Results.js and change spacer to 105px instead of 69px.
                */}
                  <div className={"row "}>


                    <div className={"col"}>
                      <div className={"paddingLeft"}>Average FPS:</div>
                    </div>
                    <div className={"col flexshrink"}>
                      <div className={"paddingRight blue"}>{avgFPS}</div>
                    </div>
                  </div>

                {/* remove to unhide
                  <div className={"row"}>
                    <div className={"col"}>
                      <div className={"paddingLeft"}>Max FPS:</div>
                    </div>
                    <div className={"col flexshrink"}>
                      <div className={"paddingRight blue"}>{maxFPS}</div>
                    </div>
                  </div>
                  */}
              </div>
              <div className={"col"}>
                {/* add marginBottom12 class to row if unhiding the max Throughput*/}
                <div className={"row "}>
                  <div className={"col"}>
                    <div className={"paddingLeft"}>Total Inference Time:</div>
                  </div>
                  <div className={"col flexshrink"}>
                    <div className={"paddingRight blue"}>{avgThroughput}</div>
                  </div>
                </div>
                {/* remove to unhide
                <div className={"row"}>
                  <div className={"col"}>
                    <div className={"paddingLeft"}>Max Throughput:</div>
                  </div>
                  <div className={"col flexshrink"}>
                    <div className={"paddingRight blue"}>{maxThroughput}</div>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>

      </Graph>
    </Fragment>
  );
}

export default ResultsGraph;
