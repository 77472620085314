// define our intial state
const appInitialState = {
  'scenario': {},
  'dataSource': null,
  'hardware': {
    'config1': [],
    'config2': []
  }
};

export default appInitialState;
