import React, { Fragment } from "react";
import styled from 'styled-components';
const breakpoint = "750px"

const ListItem = styled.li`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  position: relative;
  border:1px solid #D8D8D8;
   @media (max-width: ${breakpoint}){
    display: block;
   }
  h3 {
    font-size: 24px;
    font-weight: 400;
    margin-top:0;
    margin-bottom: 15px;
     @media (max-width: 768px){
        margin-top: 1em;
     }
      @media (max-width: ${breakpoint}) {
          margin-top: 10px;
        }
  }
  h4 {
    color: rgba(150, 150, 150, 1.000);
    font-weight: 400;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 9px;
  }
  .col1 {
    width: auto;
    display: block;
    width: 100%;
    max-width: 290px;
    padding-right: 20px;
    position: relative;
    flex-shrink: 0;
    line-height: 0;
    @media (max-width: 1023px) {
    //  max-width: 240px;
    }
    @media (max-width: ${breakpoint}) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .col2 {
    flex-grow: 1;
    font-size: 15px;
    color: #252525;
    line-height: 20px;
    display: flex;
    align-items: center;
    .padding {
      padding-top: 10px;
      padding-bottom: 10px;
       @media (max-width: ${breakpoint}) {
          padding-left: 0px;
        }
    }
    p {
      margin-top: 0;
      font-weight: 400;
      margin: 0;
    }
  }
  .row-mini {
        display: flex;
    flex-wrap: wrap;
  }
  .selectbutton {
    width: 134px;
    height: 38px;
    background: #0071C5;
    position: relative;
    cursor: pointer;
    &:after{
      color: white;
      font-size: 18px;
      content: "Select";
      text-align: center;
      width: 134px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer: cursor;
     //position: absolute;
    }
    &.active:after {
      content: "";
      background: url("/images/icons/check-mark.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .cola {
    flex-grow: 1;
    padding-right: 10px;
  }
  .colb {
    padding-right: 20px;
     @media (max-width: ${breakpoint}) {
      padding-right: 0px;
    }
  }


  img {
    height: auto;
    width: auto;
    max-width: 100%;
  }
  &.active {
    border:1px solid #F3D54E;
    .col1 {
    &:before {
        background: rgba(243, 213, 78, 0.6);
        background-repeat: no-repeat;
        background-position: center;
        width: calc(100% - 20px);
        height: calc(100% - 0px);
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: ${breakpoint}) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

function ScenarioCard(props) {

  // figure if this card is the selected scenario
  const selected = (props.selectedScenario && props.selectedScenario.id === props.scenario.id) ? 'active' : '';

  return (
    <Fragment>
      <ListItem onClick={(e) => props.selectScenario(props.scenario.id)} className={selected}>
        <div className="col1">
          <img src={props.scenario.previewImg} alt={props.scenario.id}/>
        </div>
        <div className="col2">
          <div className={"padding"}>
            <div className={"row-mini"}>
              <div className={"cola"}>
                <h3>{props.scenario.title}</h3>
                <h4>{props.scenario.inferenceDescription}</h4>
              </div>
              <div className={"colb"}>
                <div className={`${selected} selectbutton`}></div>
              </div>
            </div>


            <p>{props.scenario.hardwarePageIntro}</p>
          </div>
        </div>
      </ListItem>
    </Fragment>
  );
}

export default ScenarioCard;
