import appInitialState from './appInitialState';

const AppReducer = (state, action) => {
  switch(action.type) {
    case "SET_SCENARIO":
      console.debug('setting scenario: ', action.payload);
      return {
        ...state,
        scenario: action.payload
      }
    case "SET_HARDWARE":
      console.debug('setting hardware: ', action.payload);
      return {
        ...state,
        hardware: action.payload
      }
    case "RESET":
      console.debug('resetting...', appInitialState);
      return appInitialState
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default AppReducer;
