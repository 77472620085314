import React, {Fragment, useState} from "react";
import styled  from 'styled-components';

import { useAppContext } from '../context/AppContext';
import HardwareCard from '../atom/HardwareCard';

const StyledStuff = styled.div`
  .minicol {
    display: inline-block;
    margin-right: 40px;
  }
  .border {
    border: 1px solid white;
    display: flex;
    padding: 25px;
  }
  h4 {
    font-size: 20px;
    font-family: "Clear Sans Pro", Clear Sans Pro;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 28px;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 35px;
  }
`;

function CurrentSelections() {
  const { state } = useAppContext();
  const [selectedHardware] = useState(state.hardware);
  const [isConfig2Enabled] = useState((selectedHardware.config2.length > 0) ? true : false);

  const clickHandler = () => {
    return false
  };

  const hardwareCards1 = state.hardware.config1.map((hardware) =>
    <div key={"config1-" + hardware.id}><h4>{hardware.type}</h4><HardwareCard key={hardware.id} hardware={hardware} addHardware={ clickHandler} cssClass={"inline"}/></div>

  );

  const hardwareCards2 = state.hardware.config2.map((hardware) =>
    <div key={"config2-" + hardware.id}><h4>{hardware.type}</h4><HardwareCard key={hardware.id} hardware={hardware} addHaldware={clickHandler} cssClass={"inline"}/></div>
  );

  return (
    <Fragment>
      <StyledStuff>
        <h5>Scenario: { state.scenario && state.scenario.title }</h5>
        <div className={"row"}>
          <div className={"col-sm"}>

            <div className={"minicol"}>
              <p>Selected Configuration 1</p>
              <div className={"border"}>
                {hardwareCards1}
              </div>
            </div>
            {isConfig2Enabled &&
            <div className={"minicol"}>
              <p>Selected Configuration 2</p>
              <div className={"border"}>
                {hardwareCards2}
              </div>
            </div>
            }
          </div>
        </div>

      </StyledStuff>
    </Fragment>
  );
}

export default CurrentSelections;
