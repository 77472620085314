const hardwareData = [
  {
    'id': 'i7',
    'type': 'CPU',
    'name': '10th Gen Intel® Core i7',
    'description': "10th Gen Intel® Core™ processors with Intel® Iris® Plus graphics bring broad-scale artificial intelligence (AI) to the PC for the first time. With approximately 2.5x accelerated AI performance1, approximately 2x graphics performance2, nearly 3x faster wireless speeds3 with Intel® Wi-Fi 6 (Gig+) and the fastest4 and most versatile port available via Thunderbolt™ 3 technology, these processors bring a new level of integration to power PC experiences for today and the future.",
    'learnmore': 'https://www.intel.com/content/www/us/en/products/processors/core/i7-processors.html'
  },
  {
    'id': 'xeon',
    'type': 'CPU',
    'name': 'Intel® Xeon® Gold',
    'description': "Drive actionable insight, count on hardware-based security, and deploy dynamic service delivery with Intel® Xeon® Scalable processors. Support your hybrid cloud infrastructure and most demanding applications – including in-memory analytics, artificial intelligence, autonomous driving, high performance computing (HPC), and network transformation.",
    'learnmore': 'https://www.intel.com/content/www/us/en/products/processors/xeon/scalable.html'
  },
  {
    'id': 'atom',
    'type': 'CPU',
    'name': 'Intel® Atom®',
    'description': "Built for embedded applications. Power mobile, portable, and small-scale devices of every kind. Choose from processor/chipset combinations and system-on-chip configurations that deliver excellent performance per watt, rich graphics, and I/O integration.",
    'learnmore': 'https://www.intel.com/content/www/us/en/products/processors/atom.html'
  },
  {
    'id': 'gpu',
    'type': 'GPU',
    'name': 'Integrated Intel® GPU',
    'description': "While integrated GPUs may be found in high-performance CPUs, they have their role in machine learning inference. Their value for inference is in the heterogeneous nature of an edge compute node. That is, an edge AI application may be using the CPU for data flow automation (networking, database access, image preprocessing) and the GPU for inferencing.",
    'learnmore': 'https://www.emergeinteractive.com'
  },
  {
    'id': 'arria',
    'type': 'FPGA',
    'name': 'Intel® Arria® 10',
    'description': "Intel Vision Accelerator Design (HDDL-F) is a computing accelerator based on the Intel Arria 10 field-programmmable gate array (FPGA). It is produced in a PCIe device form-factor and designed for edge inference in high compute density servers.",
    'learnmore': ' https://www.intel.com/content/www/us/en/products/programmable/fpga/arria-10.html'
  },
  {
    'id': 'movidius',
    'type': 'VPU',
    'name': 'Intel® Movidus™ Myriad™ X based Intel® Vision Accelerator Card x8',
    'description': "Intel Vision Accelerator Design (HDDL-R) MA2485 is a computer vision and deep learning inference accelerator based on the Intel Movidius Myriad X Vision Processing Unit (VPU). It is produced in a PCIe device form-factor and designed for edge inference in high compute density servers.",
    'learnmore': 'https://www.intel.com/content/www/us/en/artificial-intelligence/movidius-myriad-vpus.html'
  }
];

export default hardwareData;
