import React, { Fragment } from "react";
import styled from 'styled-components';

const Bar = styled.div`
  width: 100%;
  background: #F3D54E;
  color: black;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  padding-right: 65px;
  padding-top: 18px;
  padding-bottom: 18px;
  //text-align: right;
  font-weight: bold;
  font-size: 19px;
  padding-left: 247px;
  div {
    display: inline-block;
    cursor: pointer;
  }
  a {
  color: black;
  }
  img {
    position: relative;
    left: 25px;
    margin-top: 5px;
  }

`;

function NextBar(props) {
  return (
    <Fragment>
      <Bar>
        <div onClick={props.myFunction}>{props.title}
          <img src={"/images/icons/chevron-down.svg"} alt="down arrow" />
        </div>
      </Bar>
    </Fragment>
  );
}

export default NextBar;
