import React, {Fragment} from "react";
import styled  from 'styled-components';

import HardwareCard from '../atom/HardwareCard';
//import InfoCard from "../atom/InfoCard";

const Container = styled.div`
    .hardwareCardRow {
          display: flex;
    }
    .hardwareTypeName {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        .col1 {
          flex-grow: initial;
        }
        .col2 {
          width: 40px;
          text-align: center;
          padding-top: 4px;
        }
        .col3 {
          flex-grow: 1;
          padding-right: 20px;
        }
        h3 {
            font-family: "Clear Sans Pro", Clear Sans Pro;
            font-size: 32px;
            font-weight: bold;
            position: relative;
            padding: 0;
            margin: 0;
        }
        hr {
          margin-top: 1em;
          border-top: 1px solid #D8D8D8;
          border-bottom: 0;
        }
    }
`;
const CPUFPGA = styled.div`
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color: #2A2A2A;
    &:not(:empty) {
      top: -34px;
      padding-bottom: 4px;
      border-bottom: 1px solid #D8D8D8;
      @media (min-width: 1048px) {
       top: -34px;
      }
      @media (max-width: 1048px) {
        left: 0;
        position: relative;
        top: 0;
        padding-top: 10px;
        margin-bottom: 10px;
        max-width: initial;
      }
      width: 100%;
      max-width: 439px;
    }
`;


function HardwareTypeContainer(props) {

  const hardwareCards = props.hardware.map((hardware) =>
    <HardwareCard key={hardware.id} configId={props.configId} selectedHardware={props.selectedHardware} hardware={hardware} addHardware={props.addHardware} displayInfo={props.displayInfo} cssClass={"padding"}/>
  );
  let optionalHeadline = ""
  if (props.hardwareType.id === "GPU" ) {
    optionalHeadline = "AI Acceleration Options";
  }
  let HR // Check
  if (props.hardwareType.id === "CPU") {
    HR = true;
  }

  return (
    <Fragment>
      <CPUFPGA>{optionalHeadline}</CPUFPGA>
      <Container className={"hardwareTypeContainer"}>
        <div className={"hardwareTypeName"}>
          <div className={"col1"}>
            <h3>{props.hardwareType.id}</h3>
          </div>
          <div className={"col2"}>
            {/*<InfoCard displayInfo={props.displayInfo} description={props.hardwareType.description}/> */}
          </div>
          <div className={"col3"}>
            {HR  &&
              <hr />
            }
          </div>
        </div>
        <div className={"hardwareCardRow"}>
          {hardwareCards}
        </div>
      </Container>
    </Fragment>
  );
};

export default HardwareTypeContainer;
