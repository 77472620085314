import React, {Fragment, useState} from "react";
import { useHistory, Link } from 'react-router-dom';
import styled  from 'styled-components';
import Share from '../atom/Share';
import VideoImageCard from "../atom/VideoImageCard";
import ResultsCardContainer from "../molecule/ResultCardContainer";
import CodeExampleContainer from "../molecule/CodeExampleContainer";

import { useAppContext } from '../context/AppContext';

const ResultsTest = styled.div`
  .container {
    max-width: 1194px;
  }
  #topbanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    background: url(/images/background/grid-sm-c.png);
    background-repeat: repeat;
    background-size: 100px 100px;
    .col1 {
      width: 88px;
      height: 88px;
      background-color: #0071C5;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 66px;
      }
    }
    .col2 {
       flex-grow: 1;
    }
    .col3 {
      display: flex;

      & >  ul.bar {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        @media (max-width: 1024px) {
          display: flex;
          flex-direction: column-reverse;
        }
         span.benchmark {
          font-size: 15px;
          font-weight: bold;
          color: #6f6f6f;
          display: inline-block;
          padding-top: 9px;
          padding-right: 9px;
        }
        li.bar {
          background-color: #0071C5;
          color: white;
          border-right: 1px white solid;
          height: 38px;
          width: 172px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          img {
            margin-right: 10px;
          }
        }
      }
    }
    h2 {
      font-family: "Clear Sans Pro", Clear Sans Pro;
      font-size: 72px;
      color: #0071C5;
      line-height: 28px;
      margin: 0;
      padding-top: 26px;
      margin-left: 30px;
      @media (max-width: 1186px) {
        font-size: 50px;
        line-height: 36px;
      }
      @media (max-width: 850px) {
        font-size: 48px;
        line-height: 36px;
      }
      @media (max-width: 665px) {
        padding-top: 6px;

      }
    }
  }
  .margintop {
    margin-top: 90px;
    @media (max-width: 1024px) {
     margin-top:110px;
    }

  }
  .top-section {
    min-height: 265px;
  }
  .bottom-section {
    padding-top: 20px;
    padding-bottom: 45px;
  }
  .copy {
    font-size: 17px;
    color: #252525;
    line-height: 21px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .button {
    min-width: 260px;
    width: 100%;
    background-color: #F3D54E;
    text-align: center;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #252525;
    font-weight: normal;
  }

  h5 {
     font-size: 17px;
     font-weight: bold;
     margin-bottom: 10px;
     @media (max-width: 1280px) {
      margin-top: 10px;
     }
  }
  .align-right {
    text-align: right;
    position: relative;
    img {
      max-width: 100%;
    }
  }
  .devcloud {
    flex-grow: 1;
  }
  .preview {
    max-width: 400px;
  }
  .maxWidth {
    max-width: 658px;
  }

  .scenario {
    margin-left: auto;
    margin-right: auto;
    bottom: 25px;
    background: #FFFFFF;
    border: 1px solid #B6B6B6;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
    font-size: 16px;
    color: #252525;
    text-align: center;
    display: inline-block;
    padding-top: 9px;
    // padding-bottom: 9px;
    width: fit-content;
    strong {
      margin-bottom: 12px;
      display: inline-block;
      margin-top: 5px;
    }
  }
  .marginBottom16px {
    margin-bottom: 16px;
  }
  .linksForHardware > .row{
    flex-wrap: nowrap;
  }
  .marginAtStart{
    margin-block-start: 1.67em;
  }
  .linkOnResult{
    display: block;
  }
  .empty {
    background: #F2F2F2;
    border: 1px solid #888888;
    .hidden {
      width: 100%;
      position: relative;
      z-index: -1;
      opacity: 0;
    }
  }
  .spacer {
    width: 100%;
    height: 69px;
    content: "":
  }
  .expand {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .addconfig {
    border: 1px solid #888888;
    display: inline-block;
    font-size: 13px;
    color: #888888;
    line-height: 17px;
    padding: 10px 30px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    z-index: 1;
   cursor: pointer;
   img {
    position: relative;
    top: 3px;
    margin-right: 7px;
   }
   &:hover { color: #5F6364}
  }
  @media (max-height: 800px) and (min-width: 768px) {
    .newCol:nth-of-type(1) {
      .graphic {
        margin-right: 50px;
      }
    }
    .newCol:nth-of-type(2) {
      .graphic {
        margin-left: 50px;
      }
    }
  }


`;

const H5 = styled.h6`
  font-size: 72px;
  margin: 0;
  line-height: 12px;
  display: inline-block;
  @media (min-width: 768px) and (max-width: 1100px) {
    font-size: 50px;
  }
  color: #C0C0C0;
`;
const H4 = styled.h4`
  font-size: 72px;
  margin: 0;
  line-height: 12px;
  display: inline-block;
`;



function Results() {
  const { state, dispatch } = useAppContext();
  let history = useHistory();
  //console.log("state.scenario.resultsType");

  const initialLocalHardwareState = {'config1':[], 'config2':[]};
  const [selectedHardware] = useState((state.hardware.config1.length > 0) ? state.hardware : initialLocalHardwareState);
  const [isConfig2Enabled] = useState((selectedHardware.config2.length > 0) ? true : false);
  //console.log(selectedHardware)


  function startOver(e) {
    dispatch({type: 'RESET', payload: null});
    history.push('/');
  }
  function addconfig() {
    history.push('/hardware');
  }

  return (
    <Fragment>
     <ResultsTest className={"bootstrap-wrapper"}>
     <div id={"topbanner"}>
       <div className={"col1"}>
         <img className="intel" src={"/images/logo/intel-logo.svg"} alt={"Intel"}/>
       </div>
       <div className={"col2"}>
         <h2>Intel® Devcloud for the Edge</h2>
       </div>
       <div className={"col3"}>
         <ul className={"bar"}>
           <li><a href={"https://www.intel.com/content/www/us/en/products/performance/benchmarks/disclosures.html"} target={"_blank"} rel={"noopener noreferrer"}><span className={"benchmark"}>Benchmark Disclosure</span></a></li>
           <Share cssClass={"results"} />
           <Link to="/" onClick={startOver}><li className={"bar"}><img src={"/images/icons/startover.svg"} alt={"start over"}/>Start Over</li></Link>
         </ul>
       </div>
     </div>
      <div className={"top-section"}>
        <div className={"container margintop"}>
          <div className={"row"}>
            <div className={"col-sm devcloud"}>
              <div className={"maxWidth"}>
                <h3>View Performance Results</h3>
                <p className={"copy"}>The graphs represent the estimated throughput and latency of your AI application on the selected hardware. Ready to try it out on actual tests?</p>
                <div className={"col-sm linksForHardware"}>
                  <div className={"row"}>
                    <h5>Intel® DevCloud for the Edge</h5>
                    <p className={"marginAtStart"}>Ready to prototype and test your AI applications?</p>
                    <a className={"marginAtStart linkOnResult"} href={"https://devcloud.intel.com/edge/"} target={"_blank"} rel="noopener noreferrer"><div className={"button"}>Get Access</div></a>
                  </div>
                  <div className={"row"}>
                    <h5>Intel® Distribution of Openvino™ Toolkit</h5>
                    <p className={"marginAtStart"}>Ready to develop with your edge hardware?</p>
                    <a className={"marginAtStart linkOnResult"} href={"https://software.seek.intel.com/openvino-toolkit?os=windows"} target={"_blank"} rel="noopener noreferrer"><div className={"button"}>Download</div></a>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-sm align-right preview"}>

              <div className={"scenario"}><strong>Scenario:</strong> {state.scenario.resultsDescription}

                <VideoImageCard resultsType={state.scenario.resultsType} results={state.scenario.results} />
                <CodeExampleContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
       <div className={"bottom-section"}>
         <div className={"container"}>
           <div className={"row"}>
             <ResultsCardContainer config={selectedHardware.config1} bigNumber={"1"}/>
             {isConfig2Enabled &&
             <ResultsCardContainer config={selectedHardware.config2} bigNumber={"2"}/>
             }
             {!isConfig2Enabled &&
             <div className={"col-sm newCol expand"}>
               <div className={"marginBottom16px"}> <H5>2</H5><H4></H4></div>
               <div className={"empty graphic"}>
                 <div className={"addconfig"} onClick={addconfig}> <img src={'/images/icons/plus-config.svg'} alt={"plus icon"}/> Add Configuration</div>
                 <img src={"/images/results/vpu.png"}  alt="placeholder" className={"hidden"}/>
                 <div className={"spacer"}> &nbsp;</div>
               </div>
             </div>
             }
           </div>
         </div>
       </div>
     </ResultsTest>
    </Fragment>
  );
}

export default Results;
