import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/AppContext";

const NotFoundContainer = styled.div`
  background: #3496dd;
  height: 100vh;
  color: white;
  h2 {
    text-transform: uppercase;
    font-size: 102px;
    font-family: "Clear Sans Pro",  Clear Sans Pro;
    color: white;
    margin-bottom: 0.25em;
    margin-top: 0;
  }
  .button {
    background: rgba(251, 206, 56, 1.000);
    box-shadow: 0 2px 64px 0 rgba(255,255,255,0.38);
    color: black;
    border-radius: 3px;
    max-width: 280px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    //height: 48px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

function NotFound() {

  const { dispatch } = useAppContext();
  let history = useHistory();

  function startOver(e) {
    dispatch({type: 'RESET', payload: null});
    history.push('/');
  }

  return (
    <Fragment>
      <NotFoundContainer className={"bootstrap-wrapper"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-sm"}>
              <h2>This is awkward...</h2>
              <p>We couldn't find what you were looking for.</p>
              <div onClick={startOver} className="button" to="/">Start the DevCloud demo over.</div>
            </div>
          </div>
        </div>
      </NotFoundContainer>
    </Fragment>
  );
}

export default NotFound;
