import React, { Fragment } from "react";
import styled from 'styled-components';
import { useAppContext } from '../context/AppContext';


const CodeBlock = styled.div`
  .token.comment {
    display: block;
  }
`;
//prism CSS
function CodeExample() {
  const { state } = useAppContext();

  if (state.scenario.id === "store") {
    return (
      <Fragment>
        <CodeBlock>
          <h1>Retail Traffic Monitor Sample Application</h1>
          <h2>Imports</h2>
          <pre className=" language-python"><code className=" language-python">
<span className="token keyword">import</span> amatplotlib<span className="token punctuation">.</span>pyplot <span
            className="token keyword">as</span> plt
<span className="token keyword">import</span> os
<span className="token keyword">import</span> time
<span className="token keyword">import</span> sys
<span className="token keyword">from</span> qarpo<span className="token punctuation">.</span>demoutils <span
            className="token keyword">import</span> <span className="token operator">*</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span className="token string">'Imported Python modules successfully.'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h2>Create the IR files for the inference model</h2>
          <pre className=" language-python"><code className=" language-python">
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/tools/model_downloader/downloader.py --print_all'</span><span
            className="token punctuation">)</span>

get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/tools/model_downloader/downloader.py --name mobilenet-ssd -o raw_models'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'echo "\\nAll files that were downloaded:"'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'find ./raw_models'</span><span className="token punctuation">)</span>

<span className="token comment">&#35; Create FP16 IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/model_optimizer/mo.py --input_model raw_models/public/mobilenet-ssd/mobilenet-ssd.caffemodel --data_type FP16 -o models/mobilenet-ssd/FP16 --scale 256 --mean_values [127,127,127] '</span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; Create FP32 IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/model_optimizer/mo.py --input_model raw_models/public/mobilenet-ssd/mobilenet-ssd.caffemodel --data_type FP32 -o models/mobilenet-ssd/FP32 --scale 256 --mean_values [127,127,127] '</span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; find all resulting IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'echo "\\nAll IR files that were created:"'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'find ./models -name "*.xml" -o -name "*.bin" '</span><span
            className="token punctuation">)</span>
</code></pre>
          <h2>Run inference</h2>
          <h3>Configure input</h3>
          <pre className=" language-python"><code className=" language-python">
<span className="token comment">&#35; Set maximum number of inference requests for CPU when using the Async API</span>
NumRequests_CPU <span className="token operator">=</span> <span className="token number">2</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string-interpolation"><span className="token string">f"Number of inference requests for CPU set to:</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>NumRequests_CPU<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">"</span></span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; Set maximum number of inference requests for CPU when using the Async API</span>
NumRequests_GPU <span className="token operator">=</span> <span className="token number">4</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string-interpolation"><span className="token string">f"Number of inference requests for GPU set to:</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>NumRequests_GPU<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">"</span></span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; Set maximum number of inference requests for NCS2 when using the Async API</span>
NumRequests_NCS2 <span className="token operator">=</span> <span className="token number">4</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string-interpolation"><span className="token string">f"Number of inference requests for NCS2 set to:</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>NumRequests_NCS2<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">"</span></span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; Set maximum number of inference requests for FPGA when using the Async API</span>
NumRequests_FPGA <span className="token operator">=</span> <span className="token number">4</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string-interpolation"><span className="token string">f"Number of inference requests for FPGA set to:</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>NumRequests_FPGA<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">"</span></span><span
            className="token punctuation">)</span>

<span
  className="token comment">&#35; Set maximum number of inference requests for HDDL-R when using the Async API</span>
NumRequests_HDDLR <span className="token operator">=</span> <span className="token number">128</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string-interpolation"><span className="token string">f"Number of inference requests for HDDL-R set to:</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>NumRequests_HDDLR<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">"</span></span><span
            className="token punctuation">)</span>
</code></pre>
          <h3>Create the job file</h3>
          <pre className=" language-python"><code className=" language-python">
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>run_cell_magic<span className="token punctuation">(</span><span
            className="token string">'writefile'</span><span className="token punctuation">,</span> <span
            className="token string">'store_traffic_monitor_job.sh'</span><span
            className="token punctuation">,</span> <span
            className="token string">'\n&#35; Store input arguments: &lt;output_directory&gt; &lt;device&gt; &lt;fp_precision&gt; &lt;num_videos&gt;\nOUTPUT_FILE=&&#35;36;1\nDEVICE=&&#35;36;2\nFP_MODEL=&&#35;36;3\nNUM_VIDEOS=&&#35;36;4\nNUM_REQ=&&#35;36;5\n\n&#35; The default path for the job is the user\'s home directory,\n&#35;  change directory to where the files are.\ncd &&#35;36;PBS_O_WORKDIR\n\n&#35; Make sure that the output directory exists.\nmkdir -p &&#35;36;OUTPUT_FILE\n\n&#35; Check for special setup steps depending upon device to be used\nif [ "&&#35;36;DEVICE" = "HETERO:FPGA,CPU" ]; then\n    &#35; Environment variables and compilation for edge compute nodes with FPGAs - Updated for OpenVINO 2020.3\n    export AOCL_BOARD_PACKAGE_ROOT=/opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/BSP/a10_1150_sg2\n    source /opt/altera/aocl-pro-rte/aclrte-linux64/init_opencl.sh\n    aocl program acl0 /opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/2020-3_PL2_FP16_MobileNet_Clamp.aocx\n    export CL_CONTEXT_COMPILER_MODE_INTELFPGA=3\nfi\n\n&#35; Set inference model IR files using specified precision\nMODELPATH=models/mobilenet-ssd/&&#35;36;&&#35;123;FP_MODEL&&#35;125;/mobilenet-ssd.xml\n\n&#35;Run the store traffic monitor code\npython3 store_traffic_monitor.py -d &&#35;36;DEVICE \\\n                                 -m &&#35;36;MODELPATH \\\n                                 -o &&#35;36;OUTPUT_FILE \\\n                                 -nr &&#35;36;NUM_REQ \\\n                                 -c conf.txt \\\n                                 -l labels.txt \\\n                                 -n &&#35;36;NUM_VIDEOS\n\necho "job submitted"'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h3>Submit to an edge compute node</h3>
          <pre className=" language-python"><code className=" language-python">
<span className="token comment">&#35;Submit job to the queue</span>
job_id_core <span className="token operator">=</span> get_ipython<span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">.</span>getoutput<span
            className="token punctuation">(</span><span className="token string">'qsub store_traffic_monitor_job.sh -l nodes= -F "results/core  &&#35;123;NumRequests_CPU&&#35;125;" -N monitor_core'</span><span
            className="token punctuation">)</span>
<span className="token keyword">print</span><span className="token punctuation">(</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">)</span>
<span className="token comment">&#35;Progress indicators</span>
<span className="token keyword">if</span> job_id_core<span className="token punctuation">:</span>
    progressIndicator<span className="token punctuation">(</span><span
            className="token string">'results/core'</span><span className="token punctuation">,</span> <span
            className="token string-interpolation"><span className="token string">f'i_progress_</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> <span
            className="token string">"Inference"</span><span className="token punctuation">,</span> <span
            className="token number">0</span><span className="token punctuation">,</span> <span
            className="token number">100</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Monitor job status</h2>
          <pre className=" language-python"><code className=" language-python">
liveQstat<span className="token punctuation">(</span><span className="token punctuation">)</span>
</code></pre>
          <h2>View results</h2>
          <pre className=" language-python"><code className=" language-python">
videoHTML<span className="token punctuation">(</span><span className="token string">''</span><span
            className="token punctuation">,</span>
          <span className="token punctuation">[</span><span className="token string-interpolation"><span
            className="token string">f'results/core/inference_output_Video_</span><span className="token interpolation"><span
            className="token punctuation">&&#35;123;</span>job_id_core<span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">&&#35;125;</span></span><span
            className="token string">_0.mp4'</span></span><span className="token punctuation">,</span>
           <span className="token string-interpolation"><span
             className="token string">f'results/core/inference_output_Video_</span><span
             className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
             className="token punctuation">[</span><span className="token number">0</span><span
             className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
             className="token string">_1.mp4'</span></span><span className="token punctuation">,</span>
           <span className="token string-interpolation"><span
             className="token string">f'results/core/inference_output_Video_</span><span
             className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
             className="token punctuation">[</span><span className="token number">0</span><span
             className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
             className="token string">_2.mp4'</span></span><span className="token punctuation">,</span>
           <span className="token string-interpolation"><span className="token string">f'results/core/Statistics_</span><span
             className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
             className="token punctuation">[</span><span className="token number">0</span><span
             className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
             className="token string">.mp4'</span></span><span className="token punctuation">]</span><span
            className="token punctuation">,</span>
           <span className="token string-interpolation"><span className="token string">f'results/core/stats_</span><span
             className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
             className="token punctuation">[</span><span className="token number">0</span><span
             className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
             className="token string">.txt'</span></span><span className="token punctuation">)</span>
</code></pre>
          <h2>View and assess performance results</h2>
          <pre className=" language-python"><code className=" language-python">
<span className="token comment">&#35; Create table of &#35;60;architecture&gt;, &lt;title&gt; for plotting</span>
arch_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">(</span><span
            className="token string">'&lt;hardware architecture&gt;'</span><span className="token punctuation">,</span> <span
            className="token string">'&lt;hardware title&gt;'</span><span className="token punctuation">)</span><span
            className="token punctuation">,</span><span className="token punctuation">]</span>
<span className="token comment">&#35; For each archtecture in table, create path to stats file or placeholder</span>
stats_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">]</span>
<span className="token keyword">for</span> arch<span className="token punctuation">,</span> a_name <span
            className="token keyword">in</span> arch_list<span className="token punctuation">:</span>
    <span
      className="token comment">&#35; if job_id_&lt;architecture&gt; exists, the job was run and has a stats file</span>
    <span className="token keyword">if</span> <span className="token string">'job_id_'</span><span
            className="token operator">+</span>arch <span className="token keyword">in</span> <span
            className="token builtin">vars</span><span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string-interpolation"><span
            className="token string">f'results/</span><span className="token interpolation"><span
            className="token punctuation">&&#35;123;</span>arch<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">/stats_</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span><span
            className="token builtin">vars</span><span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">[</span><span
            className="token string">"job_id_"</span><span className="token operator">+</span>arch<span
            className="token punctuation">]</span><span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">&&#35;125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
    <span className="token keyword">else</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string">'placeholder'</span><span
            className="token operator">+</span>arch<span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
<span className="token comment">&#35; Plot the execution time from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Time, seconds'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine Processing Time'</span><span
            className="token punctuation">,</span> <span className="token string">'time'</span><span
            className="token punctuation">)</span>
<span className="token comment">&#35; Plot the frames per second from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Frames per second'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine FPS'</span><span className="token punctuation">,</span> <span
            className="token string">'fps'</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Telemetry Dashboard</h2>
          <pre className=" language-python"><code className=" language-python">
link_t <span className="token operator">=</span> <span className="token string">" Click here to view telemetry dashboard of the last job ran on Intel® Core™ i5-6500TE"</span>

result_file <span className="token operator">=</span> <span
            className="token string">"https://devcloud.intel.com/edge/metrics/d/"</span> <span
            className="token operator">+</span> job_id_core<span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">.</span>split<span className="token punctuation">(</span><span
            className="token string">'.'</span><span className="token punctuation">)</span><span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span>

html <span className="token operator">=</span> HTML<span className="token punctuation">(</span>link_t<span
            className="token punctuation">.</span><span className="token builtin">format</span><span
            className="token punctuation">(</span>href<span className="token operator">=</span>result_file<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
display<span className="token punctuation">(</span>html<span className="token punctuation">)</span>
</code></pre>
        </CodeBlock>
      </Fragment>
    );

    /* =======================================   */
    /*              scenario                     */
    /* =======================================   */



  } else if (state.scenario.id === "intrusion") {

    return (
      <Fragment>
        <CodeBlock>
          <h1>Intrusion Detector Sample Application</h1>
          <h2>Imports</h2>
          <pre className=" language-python"><code className=" language-python">
<span className="token keyword"> import</span> matplotlib<span className="token punctuation">.</span>pyplot <span
            className="token keyword">as</span> plt
<span className="token keyword"> import</span> os
<span className="token keyword"> import</span> time
<span className="token keyword"> import</span> sys
<span className="token keyword"> from</span> qarpo<span className="token punctuation">.</span>demoutils <span
            className="token keyword">import</span> <span className="token operator">*</span>
<span className="token keyword"> print</span><span className="token punctuation">(</span><span className="token string">'Imported Python modules successfully.'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h2>Create the IR files for the inference model</h2>
          <pre className=" language-python"><code className=" language-python">
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/tools/model_downloader/downloader.py --print_all'</span><span
            className="token punctuation">)</span>

get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'/opt/intel/openvino/deployment_tools/tools/model_downloader/downloader.py --name person-vehicle-bike-detection-crossroad-0078 -o models'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'echo "All IR files that were downloaded or created:"'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'find ./models -name "*.xml" -o -name "*.bin"'</span><span
            className="token punctuation">)</span>

videoHTML<span className="token punctuation">(</span><span className="token string">'Intrusion Video'</span><span
            className="token punctuation">,</span> <span className="token punctuation">[</span><span
            className="token string">'./resources/person-bicycle-car-detection.mp4 '</span><span
            className="token punctuation">]</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Run inference</h2>
          <h3>Create the job file</h3>
          <pre className=" language-python"><code className=" language-python">
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>run_cell_magic<span className="token punctuation">(</span><span
            className="token string">'writefile'</span><span className="token punctuation">,</span> <span
            className="token string">'intruder_detector.sh'</span><span className="token punctuation">,</span>
            <span className="token string">
              '\n# Store input arguments:   \nOUTPUT_FILE=&#61;1\nDEVICE=&#61;2\nFP_MODEL=&#61;3\n\n# The default path for the job is the user\'s home directory,\n#  change directory to where the files are.\ncd &#61;PBS_O_WORKDIR\n\n# Make sure that the output directory exists.\nmkdir -p &#61;OUTPUT_FILE\n\n# Check for special setup steps depending upon device to be used\nif [ "&#61;DEVICE" = "HETERO:FPGA,CPU" ]; then\n    # Environment variables and compilation for edge compute nodes with FPGAs - Updated for OpenVINO 2020.3\n    export AOCL_BOARD_PACKAGE_ROOT=/opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/BSP/a10_1150_sg2\n    source /opt/altera/aocl-pro-rte/aclrte-linux64/init_opencl.sh\n    aocl program acl0 /opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/2020-3_PL2_FP11_YoloV3_ELU.aocx\n    export CL_CONTEXT_COMPILER_MODE_INTELFPGA=3\nfi\n\n# Set inference model IR files using specified precision\nMODELPATH=models/intel/person-vehicle-bike-detection-crossroad-0078/&#61;&#123;FP_MODEL&#125;/person-vehicle-bike-detection-crossroad-0078.xml\n\n# Run the intruder detector code\npython3 intruder-detector.py -m &#61;MODELPATH \\\n                             -lb resources/labels.txt \\\n                             -o &#61;OUTPUT_FILE \\\n                             -d &#61;DEVICE'
          </span><span
            className="token punctuation">)</span>
</code></pre>
          <h3>Submit to an edge compute node</h3>
          <pre className=" language-python"><code className=" language-python">
<span className="token comment">#Submit job to the queue</span>
job_id_core <span className="token operator">=</span> get_ipython<span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">.</span>getoutput<span
            className="token punctuation">(</span><span className="token string">'qsub intruder_detector.sh -l nodes=1: -F "results/core/ " -N intrud_core'</span><span
            className="token punctuation">)</span>
<span className="token keyword"> print</span><span className="token punctuation">(</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">)</span>
<span className="token comment">#Progress indicators</span>
<span className="token keyword"> if</span> job_id_core<span className="token punctuation">:</span>
    progressIndicator<span className="token punctuation">(</span><span
            className="token string">'results/core'</span><span className="token punctuation">,</span> <span
            className="token string-interpolation"><span className="token string">f'i_progress_</span><span
            className="token interpolation"><span className="token punctuation">&#123;</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">&#125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> <span
            className="token string">"Inference"</span><span className="token punctuation">,</span> <span
            className="token number">0</span><span className="token punctuation">,</span> <span
            className="token number">100</span><span className="token punctuation">)</span>
    </code></pre>
          <h2>Monitor job status</h2>
          <pre className=" language-python"><code className=" language-python">
liveQstat<span className="token punctuation">(</span><span className="token punctuation">)</span>
</code></pre>
          <h2>View results</h2>
          <pre className=" language-python"><code className=" language-python">
videoHTML<span className="token punctuation">(</span><span
            className="token string">'Hardware configuration 1'</span><span className="token punctuation">,</span>
          <span className="token punctuation">[</span><span className="token string-interpolation"><span
            className="token string">f'results/core/video1_</span><span className="token interpolation"><span
            className="token punctuation">&#123;</span>job_id_core<span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">&#125;</span></span><span className="token string">.mp4'</span></span><span
            className="token punctuation">]</span><span className="token punctuation">,</span><span
            className="token string-interpolation"><span className="token string">f'results/core/stats_</span><span
            className="token interpolation"><span className="token punctuation">&#123;</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">&#125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">)</span>
</code></pre>
          <h2>View and assess performance results</h2>
          <pre className=" language-python"><code className=" language-python">
<span className="token comment"># Create table of &lt;architecture&gt;, &lt;title&gt; for plotting</span>
arch_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">(</span><span
            className="token string">'  &lt;hardware architecture&gt;'</span><span
            className="token punctuation">,</span> <span
            className="token string">'  &lt;hardware title&gt;  '</span><span
            className="token punctuation">)</span><span className="token punctuation">,</span><span
            className="token punctuation">]</span>
<span className="token comment"># For each archtecture in table, create path to stats file or placeholder</span>
stats_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">]</span>
<span className="token keyword"> for</span> arch<span className="token punctuation">,</span> a_name <span
            className="token keyword">in</span> arch_list<span className="token punctuation">:</span>
    <span className="token comment"># if job_id_&lt;architecture&gt; exists, the job was run and has a stats file</span>
    <span className="token keyword"> if</span> <span className="token string">'job_id_'</span><span
            className="token operator">+</span>arch <span className="token keyword"> in</span> <span
            className="token builtin">vars</span><span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string-interpolation"><span
            className="token string">f'results/</span><span className="token interpolation"><span
            className="token punctuation">&#123;</span>arch<span className="token punctuation">&#125;</span></span><span
            className="token string">/stats_</span><span className="token interpolation"><span
            className="token punctuation">&#123;</span><span className="token builtin">vars</span><span
            className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">[</span><span className="token string">"job_id_"</span><span
            className="token operator">+</span>arch<span className="token punctuation">]</span><span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">&#125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
    <span className="token keyword"> else</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string">'placeholder'</span><span
            className="token operator">+</span>arch<span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
<span className="token comment"># Plot the execution time from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Time, seconds'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine Processing Time'</span><span
            className="token punctuation">,</span> <span className="token string">'time'</span><span
            className="token punctuation">)</span>
<span className="token comment"># Plot the frames per second from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Frames per second'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine FPS'</span><span className="token punctuation">,</span> <span
            className="token string">'fps'</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Telemetry Dashboard</h2>
          <pre className=" language-python"><code className=" language-python">
link_t <span className="token operator">=</span> <span className="token string">" Click here to view telemetry dashboard of the last job ran on Intel® Core™ i5-6500TE"</span>

result_file <span className="token operator">=</span> <span
            className="token string">"https://devcloud.intel.com/edge/metrics/d/"</span> <span
            className="token operator">+</span> job_id_core<span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">.</span>split<span className="token punctuation">(</span><span
            className="token string">'.'</span><span className="token punctuation">)</span><span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span>

html <span className="token operator">=</span> HTML<span className="token punctuation">(</span>link_t<span
            className="token punctuation">.</span><span className="token builtin">format</span><span
            className="token punctuation">(</span>href<span className="token operator">=</span>result_file<span
            className="token punctuation">)</span><span className="token punctuation">)</span>

display<span className="token punctuation">(</span>html<span className="token punctuation">)</span>
</code></pre>
        </CodeBlock>
      </Fragment>
    );
  } else {



    return (
      <Fragment>
        <CodeBlock>

          <h1>Brain Tumor Segmentation Sample Application using the Intel® Distribution of OpenVINO™ toolkit</h1>
          <h2>Imports</h2>
          <pre className=" language-python"><code className=" language-python">

<span className="token keyword"> import</span> matplotlib<span className="token punctuation">.</span>pyplot <span
            className="token keyword">as</span> plt
<span className="token keyword"> import</span> os
<span className="token keyword"> import</span> time
<span className="token keyword"> import</span> sys
<span className="token keyword">from</span> qarpo<span className="token punctuation">.</span>demoutils <span
            className="token keyword"> import</span> <span className="token operator">*</span>
<span className="token keyword">print</span><span className="token punctuation">(</span><span className="token string">'Imported Python modules successfully.'</span><span
            className="token punctuation">)</span>

<span className="token keyword">try</span><span className="token punctuation">:</span>
    <span className="token keyword"> import</span> psutil
<span className="token keyword">except</span><span className="token punctuation">:</span>
    <span className="token keyword">print</span><span className="token punctuation">(</span><span
            className="token string">"psutil not installed"</span><span className="token punctuation">)</span>
    get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'&&#35;123;sys.executable&&#35;125; -m pip install psutil'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h2>Create the IR files for the inference model</h2>
          <pre className=" language-python"><code className=" language-python">

<span className="token comment">&#35; Create FP16 IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'python3 /opt/intel/openvino/deployment_tools/model_optimizer/mo.py     --input_model /data/Healthcare_app/data/saved_model_frozen.pb     --input_shape=[1,144,144,4]     --data_type FP16      --output_dir models/FP16      --model_name saved_model'</span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; Create FP32 IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'python3 /opt/intel/openvino/deployment_tools/model_optimizer/mo.py     --input_model /data/Healthcare_app/data/saved_model_frozen.pb     --input_shape=[1,144,144,4]     --data_type FP32      --output_dir models/FP32      --model_name saved_model'</span><span
            className="token punctuation">)</span>

<span className="token comment">&#35; find all resulting IR files</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'echo "\\nAll IR files that were created:"'</span><span
            className="token punctuation">)</span>
get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>system<span className="token punctuation">(</span><span
            className="token string">'find ./models -name "*.xml" -o -name "*.bin"'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h2>Run inference</h2>
          <h3>Create the job file</h3>
          <pre className=" language-python"><code className=" language-python">

get_ipython<span className="token punctuation">(</span><span className="token punctuation">)</span><span
            className="token punctuation">.</span>run_cell_magic<span className="token punctuation">(</span><span
            className="token string">'writefile'</span><span className="token punctuation">,</span> <span
            className="token string">'brain_tumor_seg_job.sh'</span><span className="token punctuation">,</span> <span
            className="token string">'\n&#35; Store input arguments: &lt;output_directory&gt; &lt;device&gt; &lt;fp_precision&gt;\nOUTPUT_FILE=$1\nDEVICE=$2\nFP_MODEL=$3\n\n&#35; The default path for the job is the user\'s home directory,\n&#35;  change directory to where the files are.\ncd $PBS_O_WORKDIR\n\n&#35; Make sure that the output directory exists.\nmkdir -p $OUTPUT_FILE\n\n&#35; Check for special setup steps depending upon device to be used\nif [ "$DEVICE" = "HETERO:FPGA,CPU" ]; then\n    &#35; Environment variables and compilation for edge compute nodes with FPGAs - Updated for OpenVINO 2020.3\n    export AOCL_BOARD_PACKAGE_ROOT=/opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/BSP/a10_1150_sg2\n    source /opt/altera/aocl-pro-rte/aclrte-linux64/init_opencl.sh\n    aocl program acl0 /opt/intel/openvino/bitstreams/a10_vision_design_sg2_bitstreams/2020-3_PL2_FP11_InceptionV1_ResNet_VGG.aocx\n    export CL_CONTEXT_COMPILER_MODE_INTELFPGA=3\nfi\n\n&#35; Set inference model IR files using specified precision\nMODELPATH=models/$&&#35;123;FP_MODEL&&#35;125;/saved_model\n\n&#35; Run the brain tumor segmentation code\npython3 brain_tumor_segmentation.py  -IR $MODELPATH \\\n                                     -r $OUTPUT_FILE \\\n                                     -d $DEVICE'</span><span
            className="token punctuation">)</span>
</code></pre>
          <h3>Submit to an edge compute node</h3>
          <pre className=" language-python"><code className=" language-python">

<span className="token comment">&#35;Submit job to the queue</span>
job_id_core <span className="token operator">=</span> get_ipython<span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">.</span>getoutput<span
            className="token punctuation">(</span><span className="token string">'qsub brain_tumor_seg_job.sh -l nodes= -F "results/core/ " -N brain_ts_core'</span><span
            className="token punctuation">)</span>
<span className="token keyword">print</span><span className="token punctuation">(</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">)</span>
<span className="token comment">&#35;Progress indicators</span>
<span className="token keyword">if</span> job_id_core<span className="token punctuation">:</span>
    progressIndicator<span className="token punctuation">(</span><span
            className="token string">'results/core'</span><span className="token punctuation">,</span> <span
            className="token string-interpolation"><span className="token string">f'i_progress_</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span>job_id_core<span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span><span className="token punctuation">&&#35;125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> <span
            className="token string">"Inference"</span><span className="token punctuation">,</span> <span
            className="token number">0</span><span className="token punctuation">,</span> <span
            className="token number">100</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Monitor job status</h2>
          <pre className=" language-python"><code className=" language-python">

liveQstat<span className="token punctuation">(</span><span className="token punctuation">)</span>
</code></pre>
          <h2>View results</h2>
          <pre className=" language-python"><code className=" language-python">

outputHTML<span className="token punctuation">(</span><span className="token string">''</span><span
            className="token punctuation">,</span>
          <span className="token string">'results/core'</span><span className="token punctuation">,</span> <span
            className="token string">'.png'</span><span className="token punctuation">)</span>

</code></pre>
          <h2>View and assess performance results</h2>
          <pre className=" language-python"><code className=" language-python">

<span className="token comment">&#35; Create table of &lt;architecture&gt;, &lt;title&gt; for plotting</span>
arch_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">(</span><span
            className="token string">'&lt;hardware architecture&gt;'</span><span className="token punctuation">,</span> <span
            className="token string">'&lt;hardware title&gt;'</span><span className="token punctuation">)</span><span
            className="token punctuation">,</span><span className="token punctuation">]</span>
<span className="token comment">&#35; For each archtecture in table, create path to stats file or placeholder</span>
stats_list <span className="token operator">=</span> <span className="token punctuation">[</span><span
            className="token punctuation">]</span>
<span className="token keyword">for</span> arch<span className="token punctuation">,</span> a_name <span
            className="token keyword">in</span> arch_list<span className="token punctuation">:</span>
    <span
      className="token comment">&#35; if job_id_&lt;architecture&gt; exists, the job was run and has a stats file</span>
    <span className="token keyword">if</span> <span className="token string">'job_id_'</span><span
            className="token operator">+</span>arch <span className="token keyword">in</span> <span
            className="token builtin">vars</span><span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string-interpolation"><span
            className="token string">f'results/</span><span className="token interpolation"><span
            className="token punctuation">&&#35;123;</span>arch<span
            className="token punctuation">&&#35;125;</span></span><span className="token string">/stats_</span><span
            className="token interpolation"><span className="token punctuation">&&#35;123;</span><span
            className="token builtin">vars</span><span className="token punctuation">(</span><span
            className="token punctuation">)</span><span className="token punctuation">[</span><span
            className="token string">"job_id_"</span><span className="token operator">+</span>arch<span
            className="token punctuation">]</span><span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">&&#35;125;</span></span><span
            className="token string">.txt'</span></span><span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
    <span className="token keyword">else</span><span className="token punctuation">:</span>
        stats_list<span className="token punctuation">.</span>append<span className="token punctuation">(</span><span
            className="token punctuation">(</span><span className="token string">'placeholder'</span><span
            className="token operator">+</span>arch<span className="token punctuation">,</span> a_name<span
            className="token punctuation">)</span><span className="token punctuation">)</span>
<span className="token comment">&#35; Plot the execution time from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Time, seconds'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine Processing Time'</span><span
            className="token punctuation">,</span> <span className="token string">'time'</span><span
            className="token punctuation">)</span>
<span className="token comment">&#35; Plot the frames per second from the stats files</span>
summaryPlot<span className="token punctuation">(</span>stats_list<span className="token punctuation">,</span> <span
            className="token string">'Architecture'</span><span className="token punctuation">,</span> <span
            className="token string">'Frames per second'</span><span className="token punctuation">,</span> <span
            className="token string">'Inference Engine FPS'</span><span className="token punctuation">,</span> <span
            className="token string">'fps'</span><span className="token punctuation">)</span>
</code></pre>
          <h2>Telemetry Dashboard</h2>
          <pre className=" language-python"><code className=" language-python">

link_t <span className="token operator">=</span> <span
            className="token string">"&lt;a target='_blank' href='&&#35;123;href&&#35;125;'&gt; Click here to view telemetry dashboard of the last job ran on Intel® Core™ i5-6500TE"</span>

result_file <span className="token operator">=</span> <span
            className="token string">"https://devcloud.intel.com/edge/metrics/d/"</span> <span
            className="token operator">+</span> job_id_core<span className="token punctuation">[</span><span
            className="token number">0</span><span className="token punctuation">]</span><span
            className="token punctuation">.</span>split<span className="token punctuation">(</span><span
            className="token string">'.'</span><span className="token punctuation">)</span><span
            className="token punctuation">[</span><span className="token number">0</span><span
            className="token punctuation">]</span>

html <span className="token operator">=</span> HTML<span className="token punctuation">(</span>link_t<span
            className="token punctuation">.</span><span className="token builtin">format</span><span
            className="token punctuation">(</span>href<span className="token operator">=</span>result_file<span
            className="token punctuation">)</span><span className="token punctuation">)</span>

display<span className="token punctuation">(</span>html<span className="token punctuation">)</span>
</code></pre>


        </CodeBlock>
      </Fragment>
    );
  }


}

export default CodeExample;



