const scenarioData = [
  {
    "id": "store",
    "title": "Retail Traffic Monitor",
    "inferenceDescription": "Classification Object detection",
    "previewImg": "/images/scenerio/car.jpg",
    "hardwarePageIntro": "You are the owner of Tasty Bites Dinnerware Emporium and have difficulty keeping certain products in stock. You aren’t sure if this is due to theft or purchasing of a popular item. You’ve set up cameras to monitor how many people visit your aisles throughout the day so you can identify what products are removed and what products customers are browsing. This demo applies the Intel® Distribution of OpenVINO™ Toolkit to detect people in a store.",
    "resultsType": "video",
    "results": "/images/results/Post-Inference--StoreTrafficMonitor.mp4",
    "resultsDescription": "Retail Traffic Monitor Inference Results",
    'selectHardware': 'This is FPO paragraph copy for store monitoring. This is FPO paragraph copy for store monitoring. This is FPO paragraph copy for store monitoring. This is FPO paragraph copy for store monitoring. This is FPO paragraph copy for store monitoring. This is FPO paragraph copy for store monitoring.'
  },
  {
    "id": "tumor",
    "title": "Brain Tumor Segmentation",
    "inferenceDescription": "Object detection",
    "previewImg": "/images/scenerio/braintumor.jpg",
    "hardwarePageIntro": "You are an overworked radiologist responsible for reviewing MRI scans from dozens of hospitals to help physicians accurately diagnose and treat brain tumors. You set up a deep learning solution that can pinpoint abnormalities to help you more quickly identify where tumors exist.",
    "resultsType": "image",
    "results": "/images/results/braintumor.jpg",
    "resultsDescription": "Brain Tumor Inference Results",
    'selectHardware': 'This is FPO paragraph copy for pneumonia detection. This is FPO paragraph copy for pneumonia detection. This is FPO paragraph copy for pneumonia detection. This is FPO paragraph copy for pneumonia detection. This is FPO paragraph copy for pneumonia detection. '
  },
  {
    "id": "intrusion",
    "title": "Intrusion Detection",
    "inferenceDescription": "Object detection",
    "previewImg": "/images/scenerio/shop.jpg",
    "hardwarePageIntro": "You are responsible for inventory of a warehouse containing valuable rare artifacts from around the world. To protect the contents after hours, you've set up a smart video AI solution to monitor the parking lot and building perimeter after hours for possible intruders and alert you.",
    "resultsType": "video",
    "results": "/images/results/Post-Inference--Intrusion.mp4",
    "resultsDescription": "Intrusion Detection Inference Results",
    'selectHardware': 'This is FPO paragraph copy for intrusion detection. This is FPO paragraph copy for intrusion detection. This is FPO paragraph copy for intrusion detection. This is FPO paragraph copy for intrusion detection. This is FPO paragraph copy for intrusion detection. '
  }
];

export default scenarioData;
