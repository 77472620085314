import React, { Fragment } from "react";
import { useAppContext } from '../context/AppContext';
import { useHistory } from "react-router-dom";
import scenarioData from '../../data/scenarioData';
import Nav from '../atom/Nav';
import styled from 'styled-components';
import ScenarioCard from '../atom/ScenarioCard';
import NextBar from '../atom/NextBar';

const ScenarioContainer = styled.div`
  .container {
    @media (max-height: 700px) {
      max-width: 100%;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  h2 {
    text-transform: uppercase;
    font-size: 50px;
    font-family: "Clear Sans Pro", Clear Sans Pro;
    margin-top: 0.55em;
    margin-bottom: 0em;
    @media (max-width: 1000px) {
      margin-top: 10px;
    }
    @media (max-height: 800px) {
      margin-top: 10px;
    }
  }
  p {
    font-weight: 300;
    font-size: 15px;
    color: #555555;
    line-height: 21px;
    margin-top: 8px;
  }
  .copy {
    max-width: 650px;
    @media (max-height: 710px) {
      max-width: 100%;
    }
  }
`;


function Scenario() {
  const { dispatch, state } = useAppContext();
  let history = useHistory();

  //passes into NextBar
  function selectHardware(e) {
    // use router send user to next page,
    history.push('/hardware');
  };

  function selectScenario(scenarioId,e) {
    //find the users selection in our data
    const userSelection = scenarioData.find(x => x.id === scenarioId);
    // validate selection exists in our data
    if (userSelection) {
      // dispatch action so reducer can update state with selected scenario
      dispatch({type: 'SET_SCENARIO', payload: userSelection});
    } else {
      // TODO handle invalid selection
      console.log('Invalid selection');
    }
  };

  // build list of scenario options based on data
  const scenarioItems = scenarioData.map((scenario) =>
    <ScenarioCard key={scenario.id} scenario={scenario} selectedScenario={state.scenario} selectScenario={selectScenario} />
  );

  return (
    <Fragment>
      <Nav />
      <ScenarioContainer className={"bootstrap-wrapper isNavBar"}>
        <div className={"container"}>
          <div className={"row"}>
              <div className={"col-sm "+ state.scenario.id }>
                <h2>Select a Computer Vision Scenario</h2>
                <p className={"copy"}>The computer vision scenarios below represent sample applications available on the Intel® DevCloud for the Edge. Select a scenario to test how an AI model will perform on various combinations of Intel hardware.</p>
                <ul>
                  {scenarioItems}
                </ul>
                {state.scenario && state.scenario.id &&
                  <NextBar myFunction={selectHardware} title="Select Hardware" />
                }
              </div>
            </div>
        </div>
      </ScenarioContainer>
    </Fragment>
  );
}

export default Scenario;
