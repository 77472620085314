const hardwareTypeData = [
  {
    'id': 'CPU',
    'description': "CPU description"
  },
  {
    'id': 'GPU',
    'description': "GPU description"
  },
  {
    'id': 'FPGA',
    'description': "FPGA description"
  },
  {
    'id': 'VPU',
    'description': "VPU description"
  }
];

export default hardwareTypeData;
