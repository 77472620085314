import React, {Fragment} from "react";
import styled from 'styled-components';

const Scenarios = styled.div`
  video { width: 100%}
  img { width: 100% }
`;

function VideoImageCard(props) {
  let isVid;
  if ( props.resultsType === "video") {
    isVid = true
  } else {
    isVid = false;
  }

  return(
    <Fragment>
      <Scenarios>
        {isVid
          ? <video controls={true} muted={true}> <source src={props.results} type={"video/mp4"} /></video>
          : <img src={props.results} alt={"result graphic"}/>
        }
      </Scenarios>
    </Fragment>
  );
}

export default VideoImageCard;
