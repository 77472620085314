// solo is when a hardware configuration does not have an additional accelerator.
// each scenario corralates with the scenarioData

const graphData =  {
  'store': {
    'graphScales': {
      "maxFPS": '180',
      "maxThroughput": '80',
    },
    'i7': {
      'solo'  : {
        'maxfps': '72',
        'avgfps': '69',
        'maxthroughput': '48',
        'avgthroughput': '46'
      },
      'gpu'  : {
        'maxfps': '102',
        'avgfps': '102',
        'maxthroughput': '32',
        'avgthroughput': '31'
      },
      'arria'  : {
        'maxfps': '210',
        'avgfps': '152',
        'maxthroughput': '19',
        'avgthroughput': '18'
      },
      'movidius'  : {
        'maxfps': '153',
        'avgfps': '153',
        'maxthroughput': '18',
        'avgthroughput': '18'
      }
    },
    'xeon': {
      'solo'  : {
        'maxfps': '140',
        'avgfps': '138',
        'maxthroughput': '25',
        'avgthroughput': '23'
      },
      'gpu'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'arria'  : {
        'maxfps': '210',
        'avgfps': '152',
        'maxthroughput': '18',
        'avgthroughput': '18'
      },
      'movidius'  : {
        'maxfps': '153',
        'avgfps': '153',
        'maxthroughput': '23',
        'avgthroughput': '18'
      }
    },
    'atom': {
      'solo'  : {
        'maxfps': '50',
        'avgfps': '50',
        'maxthroughput': '70',
        'avgthroughput': '70'
      },
      'gpu'  : {
        'maxfps': '57',
        'avgfps': '46',
        'maxthroughput': '56',
        'avgthroughput': '68'
      },
      'arria'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'movidius'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      }
    }
  },
  'tumor': {
    'graphScales': {
      "maxFPS": '120',
      "maxThroughput": '5',
    },
    'i7': {
      'solo'  : {
        'maxfps': '44',
        'avgfps': '38',
        'maxthroughput': '0.30',
        'avgthroughput': '0.21'
      },
      'gpu'  : {
        'maxfps': '50',
        'avgfps': '27',
        'maxthroughput': '0.30',
        'avgthroughput': '0.29'
      },
      'arria'  : {
        'maxfps': '50',
        'avgfps': '43',
        'maxthroughput': '0.18',
        'avgthroughput': '0.18'
      },
      'movidius'  : {
        'maxfps': '19',
        'avgfps': '15',
        'maxthroughput': '0.52',
        'avgthroughput': '0.52'
      }
    },
    'xeon': {
      'solo'  : {
        'maxfps': '105',
        'avgfps': '103',
        'maxthroughput': '0.11',
        'avgthroughput': '0.08'
      },
      'gpu'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'arria'  : {
        'maxfps': '50',
        'avgfps': '43',
        'maxthroughput': '0.18',
        'avgthroughput': '0.18'
      },
      'movidius'  : {
        'maxfps': '19',
        'avgfps': '15',
        'maxthroughput': '0.52',
        'avgthroughput': '0.52'
      }
    },
    'atom': {
      'solo'  : {
        'maxfps': '19',
        'avgfps': '5',
        'maxthroughput': '0.48',
        'avgthroughput': '0.14'
      },
      'gpu'  : {
        'maxfps': '28',
        'avgfps': '8',
        'maxthroughput': '0.33',
        'avgthroughput': '0.09'
      },
      'arria'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'movidius'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      }
    }
  },
  'intrusion': {
    'graphScales': {
      "maxFPS": '180',
      "maxThroughput": '500',
    },
    'i7': {
      'solo'  : {
        'maxfps': '35',
        'avgfps': '20',
        'maxthroughput': '32',
        'avgthroughput': '32'
      },
      'gpu'  : {
        'maxfps': '23',
        'avgfps': '18',
        'maxthroughput': '35',
        'avgthroughput': '35'
      },
      'arria'  : {
        'maxfps': '1',
        'avgfps': '1',
        'maxthroughput': '450',
        'avgthroughput': '450'
      },
      'movidius'  : {
        'maxfps': '160',
        'avgfps': '154',
        'maxthroughput': '18',
        'avgthroughput': '18'
      }
    },
    'xeon': {
      'solo'  : {
        'maxfps': '40',
        'avgfps': '40',
        'maxthroughput': '19',
        'avgthroughput': '16'
      },
      'gpu'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'arria'  : {
        'maxfps': '10',
        'avgfps': '1',
        'maxthroughput': '450',
        'avgthroughput': '450'
      },
      'movidius'  : {
        'maxfps': '154',
        'avgfps': '154',
        'maxthroughput': '18',
        'avgthroughput': '18'
      }
    },
    'atom': {
      'solo'  : {
        'maxfps': '20',
        'avgfps': '20',
        'maxthroughput': '86',
        'avgthroughput': '70'
      },

      'gpu'  : {
        'maxfps': '15',
        'avgfps': '10',
        'maxthroughput': '70',
        'avgthroughput': '62'
      },
      'arria'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      },
      'movidius'  : {
        'maxfps': '0',
        'avgfps': '0',
        'maxthroughput': '0',
        'avgthroughput': '0'
      }
    }
  }
};

export default graphData;
