import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import styled  from 'styled-components';

import { useAppContext } from '../context/AppContext';
import CurrentSelections from '../atom/CurrentSelections';


const RunContainer = styled.div`
  background: #0072c7;
  color: white;
  min-height: 100vh;
  top: 0;
  position: relative;
  margin: 0;
  padding: 0;
  background: radial-gradient(86% 77%, rgba(0, 113, 197, 0.8) 0%, rgba(0, 83, 156, 0.8) 43%,  rgba(0, 60, 113, 1) 100%);
  background-repeat:
      no-repeat;
  background-position:
      top left;
  background-size: 100%;
  overflow: hidden;
  position: relative;
  @media (max-height: 700px) {
     overflow: auto;
    }
  .container {
    position: relative;
    z-index: 10;
    max-width: 1190px;
  }
  @keyframes splashAnimation {
    0%   {
      transform: rotate(0deg) scale(1, 1);
    }
    25% {
    transform: rotate(4deg) scale(1.2, 1.2);
    }
    75% {
     transform: rotate(-2.5deg) scale(1.1, 1.1);
    }

    100% {
      transform: rotate(0deg) scale(1, 1);
    }
  }
  &:before{
    //z-index:-1;
    position:absolute;
    left:0;
    top:0;
    content: "";
    background-image: url(/images/background/worn-dots-repeat.png);
    background-repeat: repeat;
    background-size: 222px 148px;
    opacity:0.4;
    width: 100%;
    height: 100vh;
    mix-blend-mode: overlay;
  }
  &:after {
    position:absolute;
    left:0;
    top:0;
    content: "";
    background-image: url(/images/background/lightsbmp.jpg);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    opacity:0.4;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vw;
    overflow: hidden;
    perspective: 1200px;
    mix-blend-mode: overlay;
    animation-name: splashAnimation;
    animation-duration: 55s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    @media (max-height: 700px) {
     animation-name: none;
    }
  }
  h2, h3 {
    font-family: "Clear Sans Pro", Clear Sans Pro;
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 82px;
    color: #FFFFFF;
    margin-top: 40px;
    @media (max-width: 1023px) {
     margin-top: 20px;
    }
    @media (max-width: 1200px) {

      font-size: 60px;
    }
  }
  .positioned {
    position: absolute;
    bottom: 80px;
    width: 100%;
     h3 {
      text-align: right;
      font-size: 46px;

    }
  }

  h5 {
    margin-top: 80px;
    @media (max-height: 730px) {
      margin-top: 0px;
    }
  }
  .copy {
    max-width: 575px;
    font-size: 18px;
  }
  .text-right {
    text-align: right;
    @media (max-width: 1023px) {
     text-align: center;
    }
  }
  .font-size {
    margin-top: 58px;
    font-size: 15px;
    @media (max-width: 1023px) {
     margin-top: 30px;
    }
  }
  .animated {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 45px;
    background: #F3D54E;
    width: 0;
    animation-name: bar-animation;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    timing-function: linear;
    color: black;
    display: flex;
    align-items: center;
    padding-left: 1em;
    font-weight: bold;
    font-family: "Clear Sans Pro", Clear Sans Pro;
    font-size: 39px;
    line-height: 39px;
    div {
      width: 100%;
      overflow: hidden;
      text-align: right;
      margin-bottom: 4px;
      margin-right: 16px;
    }
    &:after {

    }
  }
  .logo {
    width: 340px;
  }
  .loading {
    position: relative;
    top: 3px;
    left: 10px;
    width: 65px;
    transform: rotate(0deg);
    animation:rotation 8s linear infinite;
    @media (max-width: 1200px) {
      width: 48px;
    }
  }
  .minicol {
    p {
    margin-top: 0;
    }
  }
  @keyframes bar-animation {
    from {width: 0;}
    to {width: 100%;}
  }
   @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

`;

function Run() {
  const { state } = useAppContext();

  const [counter, setCounter] = useState(0);
  const endNr = 100,  // end count for timer
    timeOut = 35; //speed (smaller = faster)

  // count up timer
  useEffect(() => {
    counter < endNr && setTimeout(() => setCounter(counter + 1), timeOut);
  }, [counter]);

  // use must have scenario & hardware selected before they can be here
  if (!state.scenario || !state.scenario.id || !state.hardware.config1.length) {
    return <Redirect to='/scenario' />
  }

  if (counter >= endNr) {
    return <Redirect to="/results" />
  }

  return (
    <Fragment>
      <RunContainer>
        <div className="bootstrap-wrapper ">
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-lg-7"}>
                <h2>Running Inference Test <img src={"/images/icons/loading.svg"} className={"loading"} alt="loading icon" /></h2>
              </div>
              <div className={"col-sm text-right"}>
                <p className={"font-size"}>Processing with the:  <strong>Intel® Distribution of OpenVINO™ toolkit.</strong></p>
                <img src={"/images/logo/openvino.png"} className={"logo"} alt="OpenVINO logo" />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-sm"}>
                <CurrentSelections />
                {/* TODO: remove link and replace with auto-redirect */}
              </div>
            </div>
          </div>
        </div>
        <div className={"positioned"}>
          <div className={"bootstrap-wrapper"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col-sm"}>
                  <h3>Intel® Devcloud for the Edge</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"animated"}>
          <div>{counter}%</div>
        </div>
      </RunContainer>
    </Fragment>
  );
}

export default Run;
